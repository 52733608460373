import { ShoeboxItemResponseDto, StorageType } from "src/backend";
import { DefaultConsolidatedView, FileConsolidatedView, FormElementConsolidatedView, ShoeboxItemConsolidatedView } from "src/contexts/form-element-context.types";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";

import { UploadContextDropElement, UploadContextDropFilesArgs } from "./upload-form-element-context";

type MergeTargetType = 'SHOEBOX_ITEM' | 'ELEMENT';

interface StagedForMerge {
    target: {
        type: "SHOEBOX_ITEM",
        element: ShoeboxItemResponseDto | null,
    } | {
        type: "ELEMENT",
        element: FormElementV2ResponseDtoExtended | null,
    };
    elements: FormElementV2ResponseDtoExtended[];
    files: File[];
    checkedFiles: File[];
    hasFilesNeedsConvertToPdf: boolean;
    loading: boolean;
}

interface StagedForCopyMoveBase {
    operation: 'COPY' | 'MOVE' | null,
    loanId: string;
}

interface StagedForCopyMoveElements extends StagedForCopyMoveBase {
    type: "ELEMENTS";
    data: FormElementV2ResponseDtoExtended[]
}

interface StagedForCopyMoveShoeboxItems extends StagedForCopyMoveBase {
    type: "SHOEBOX_ITEMS";
    data: ShoeboxItemResponseDto[]
}

type StagedForCopyMove = StagedForCopyMoveElements | StagedForCopyMoveShoeboxItems;

export interface FormElementContextReducerInitialState {
    isLoadingDialogOpen: boolean;
    assignFolderDroppedItems: {
        targetElement: FormElementV2ResponseDtoExtended;
        files: UploadContextDropFilesArgs | null;
        elements: UploadContextDropElement[] | null
    } | null;
    consolidatedView: DefaultConsolidatedView | FileConsolidatedView | ShoeboxItemConsolidatedView | FormElementConsolidatedView;
    isOperationInProgress: boolean;
    operation: 'DELETE' | 'RENAME' | null;
    data: FormElementV2ResponseDtoExtended[] | null;
    stagedForMerge: StagedForMerge;
    stagedForAction: StagedForAction;
    stagedForCopyMove: StagedForCopyMove;
    confirmOverwriteAcceptedElement: FormElementV2ResponseDtoExtended | null;
    stagedShoeboxItemsForDelete: Pick<ShoeboxItemResponseDto, 'id'>[];
    editOneDriveFile: {
        element: FormElementV2ResponseDtoExtended;
        isOpen: boolean;
        isLoading: boolean;
    }
    newFolderElement: {
        target: FormElementV2ResponseDtoExtended | null;
        folderName: string;
    },
    undoUploadElements: {
        element: FormElementV2ResponseDtoExtended
        documentId: string
        droppedFiles: File[]
    }[]
    undoSubmitElements: Pick<FormElementV2ResponseDtoExtended, 'id' | 'loanId' | 'title'>[]
}

type StagedForAction = {
    action: "LOCKED_WARNING" | null
    element: FormElementV2ResponseDtoExtended | null;
}


const initialState: FormElementContextReducerInitialState = {
    stagedShoeboxItemsForDelete: [],
    isLoadingDialogOpen: false,
    assignFolderDroppedItems: null,
    isOperationInProgress: false,
    editOneDriveFile: {
        element: null,
        isOpen: false,
        isLoading: false
    },
    operation: null,
    data: null,
    stagedForMerge: {
        target: {
            type: 'ELEMENT' as MergeTargetType,
            element: null,
        },
        elements: [],
        checkedFiles: [],
        files: [],
        hasFilesNeedsConvertToPdf: false,
        loading: false
    },
    stagedForAction: {
        action: null,
        element: null
    },
    stagedForCopyMove: {
        type: null,
        operation: null,
        data: [],
        loanId: ''
    },
    confirmOverwriteAcceptedElement: null,
    newFolderElement: {
        target: null,
        folderName: ''
    },
    undoUploadElements: [],
    undoSubmitElements: [],
    consolidatedView: {
        dropType: "DEFAULT" as const,
        storageType: 'FOLDER',
        formElement: null
    }
}

type Action =
    | {
        type: 'SET_STAGED_ELEMENTS',
        payload: {
            operation: 'DELETE' | 'RENAME' | null,
            data: FormElementV2ResponseDtoExtended[] | null
        }
    }
    | {
        type: 'SET_EDIT_ONE_DRIVE_FILE',
        payload: FormElementContextReducerInitialState['editOneDriveFile']
    }
    | { type: 'SET_STAGED_FOR_MERGE', payload: StagedForMerge }
    | {
        type: "CHECK_STAGED_FILES_FOR_MERGE",
        // file index in stagedForMerge.files
        payload: number;
    }
    | {
        type: "SELECT_ALL_FILES_FOR_MERGE_CHECKED_CHANGE",
        payload: boolean;
    }
    | {
        type: "SET_ASSIGN_FOLDER_DROPPED_ITEMS",
        payload: {
            targetElement: FormElementV2ResponseDtoExtended;
            files: UploadContextDropFilesArgs | null;
            elements: UploadContextDropElement[] | null
        } | null;
    }
    | {
        type: "SET_MERGE_LOADING",
        payload: boolean;
    }
    | {
        type: "SET_STAGED_FOR_ACTION",
        payload: StagedForAction;
    }
    | {
        type: "SET_STAGED_FOR_COPY_MOVE",
        payload: StagedForCopyMove;
    }
    | {
        type: "SET_OPERATION_IN_PROGRESS",
        payload: boolean;
    }
    | {
        type: "SET_CONFIRM_OVERWRITE_ACCEPTED_ELEMENT",
        payload: FormElementV2ResponseDtoExtended;
    }
    | {
        type: "SET_NEW_FOLDER_ELEMENT",
        payload: {
            target: FormElementV2ResponseDtoExtended | null;
            folderName: string;
        };
    } | {
        type: 'SET_UNDO_UPLOAD_ELEMENTS',
        payload: FormElementContextReducerInitialState['undoUploadElements']
    }
    | {
        type: 'SET_UNDO_SUBMIT_ELEMENTS',
        payload: FormElementContextReducerInitialState['undoSubmitElements']
    }
    | {
        type: 'SET_CONSOLIDATED_VIEW',
        payload: FormElementContextReducerInitialState['consolidatedView']
    } | {
        type: 'SET_IS_LOADING_DIALOG_OPEN',
        payload: FormElementContextReducerInitialState['isLoadingDialogOpen']
    }
    | {
        type: 'SET_STAGED_SHOEBOX_ITEMS_FOR_DELETE',
        payload: FormElementContextReducerInitialState['stagedShoeboxItemsForDelete']
    }

const reducer = (state: FormElementContextReducerInitialState, action: Action) => {

    switch (action.type) {
        case 'SET_STAGED_ELEMENTS':
            return { ...state, operation: action.payload.operation, data: action.payload.data };
        case 'SET_STAGED_FOR_MERGE':
            return { ...state, stagedForMerge: action.payload };
        case 'CHECK_STAGED_FILES_FOR_MERGE': {
            const checkedFiles = [...state.stagedForMerge.checkedFiles];
            const file = state.stagedForMerge.files[action.payload];
            if (checkedFiles.includes(file)) {
                return { ...state, stagedForMerge: { ...state.stagedForMerge, checkedFiles: checkedFiles.filter(checkedFile => checkedFile !== file) } }
            }
            return { ...state, stagedForMerge: { ...state.stagedForMerge, checkedFiles: [...checkedFiles, file] } }
        }
        case 'SELECT_ALL_FILES_FOR_MERGE_CHECKED_CHANGE':
            return { ...state, stagedForMerge: { ...state.stagedForMerge, checkedFiles: action.payload ? state.stagedForMerge.files : [] } }
        case 'SET_MERGE_LOADING':
            return { ...state, stagedForMerge: { ...state.stagedForMerge, loading: action.payload } }
        case 'SET_STAGED_FOR_COPY_MOVE':
            return { ...state, stagedForCopyMove: action.payload }
        case 'SET_OPERATION_IN_PROGRESS':
            return { ...state, isOperationInProgress: action.payload }
        case 'SET_CONFIRM_OVERWRITE_ACCEPTED_ELEMENT':
            return { ...state, confirmOverwriteAcceptedElement: action.payload }
        case 'SET_NEW_FOLDER_ELEMENT':
            return { ...state, newFolderElement: action.payload }
        case 'SET_UNDO_UPLOAD_ELEMENTS':
            return { ...state, undoUploadElements: action.payload }
        case 'SET_UNDO_SUBMIT_ELEMENTS':
            return { ...state, undoSubmitElements: action.payload }
        case 'SET_EDIT_ONE_DRIVE_FILE':
            return { ...state, editOneDriveFile: action.payload }
        case 'SET_ASSIGN_FOLDER_DROPPED_ITEMS':
            return { ...state, assignFolderDroppedItems: action.payload }
        case 'SET_CONSOLIDATED_VIEW':
            return {
                ...state,
                consolidatedView: action.payload
            }
        case 'SET_IS_LOADING_DIALOG_OPEN':
            return {
                ...state,
                isLoadingDialogOpen: action.payload
            }
        case 'SET_STAGED_SHOEBOX_ITEMS_FOR_DELETE':
            return {
                ...state,
                stagedShoeboxItemsForDelete: action.payload
            }
        default:
            return state;
    }
}

export const UploadFormElementContextReducer = {
    initialState: initialState,
    reducer
}