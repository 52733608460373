import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

const getChildrenOfContainer = (container: HTMLElement) => {
    return Array.from(container.children) as HTMLElement[];
};

export const useVisibleChildren = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [visibleCount, setVisibleCount] = useState(0);

    const updateVisibleChildren = useCallback(() => {
        if (!containerRef.current) return;

        const containerWidth = containerRef.current.offsetWidth;
        const children = getChildrenOfContainer(containerRef.current);
        let totalWidth = 0;
        let visibleChildren = 0;

        for (let i = 0; i < children.length; i++) {
            totalWidth += children[i].offsetWidth;
            if (totalWidth <= containerWidth) {
                visibleChildren = i + 1;
            } else {
                break;
            }
        }
        setVisibleCount(visibleChildren);
    }, []);

    const debouncedUpdate = useDebounceCallback(updateVisibleChildren, 100);

    useEffect(() => {
        const resizeObserver = new MutationObserver(updateVisibleChildren);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current, {
                // observe all attribute expect scroll height
                attributes: true,
                childList: true,
                subtree: true
            });
        }

        updateVisibleChildren();

        return () => {
            resizeObserver.disconnect();
        };
    }, [debouncedUpdate, updateVisibleChildren]);

    return {
        containerRef,
        visibleCount
    };
};
