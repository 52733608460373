import { useListener } from "polyrhythm-react";
import { useLayoutEffect, useState, useTransition } from "react";
import { toast } from "react-toastify";
import { useUser } from "src/hooks/use-user";
import { useGetLoanByIdQuery, useGetLoansViewQuery } from "src/services/loanApi";
import { useGetLoanElementsQuery, useLazyGetLoanElementsQuery } from "src/services/packageApi";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import { Loan } from "src/types/loan";
import { findSectionByIdAndItsAncestors } from "src/utils";

import { ICopyMoveToDialog } from "./copy-move-to-dialog.types";
import { getCopyMoveDialogTitle } from "./copy-move-to-dialog.utils";

const getActonText = (operation: ICopyMoveToDialog['props']['operation'], element: FormElementV2ResponseDtoExtended) => {
    if (!element) return "Copy to"
    // Copy/Move {{Form/File title}} to {{Folder title}}
    return `${operation === 'COPY' ? 'Copy' : 'Move'} to ${element.title}`;
}
export const useCopyMoveToDialogState = (props: ICopyMoveToDialog['props']) => {
    const [state, setState] = useState<{
        loanId: string,
        activeElement: FormElementV2ResponseDtoExtended,
        copyToElement: FormElementV2ResponseDtoExtended,
    }>({
        loanId: props.defaultLoanId,
        activeElement: null,
        copyToElement: null,
    });

    const { loanId, activeElement, copyToElement } = state;
    const userState = useUser();
    const [isPending, setTransition] = useTransition();
    const [firstElement] = props.type === "ELEMENTS" ? props.elements : [];
    const { data: loans = [] } = useGetLoansViewQuery({
        categories: [
            'LEAD',
            'ORIGINATION',
            'PORTFOLIO',
        ]
    });
    const { currentData: loan, isLoading: isLoadingLoan } = useGetLoanByIdQuery(loanId);
    const [getLoanElements] = useLazyGetLoanElementsQuery();
    const { currentData: elementsData = { list: [], rootElementId: null }, isLoading: isLoadingElements } = useGetLoanElementsQuery({
        id: loanId,
        view: 'CONVENTIONAL',
        status: 'ACTIVE'
    }, {
        skip: !loanId
    });

    const onLoanBreadcrumbClick = () => {
        setTransition(() => {
            setState({
                activeElement: null,
                loanId: null,
                copyToElement: null,
            });
            setState({
                activeElement: null,
                loanId: null,
                copyToElement: null,
            });
        });
    }

    const onLoanClick = (loan: Loan) => {
        setTransition(() => {
            setState({
                activeElement: null,
                loanId: loan.id,
                copyToElement: null,
            });
        });
    }

    const onElementClick = (element: FormElementV2ResponseDtoExtended) => {
        setTransition(() => {
            setState(prevState => {

                // if the element is already selected, deselect it
                // by selecting its parent
                if (prevState?.activeElement?.id === element.id) {
                    return ({
                        ...prevState,
                        activeElement: findParentElement(elementsData.list, element)
                    });
                }
                return ({
                    ...prevState,
                    activeElement: element
                });
            });
        });
    }

    let activeFolderElementId = elementsData?.rootElementId
    if (activeElement && activeElement.storageType === "FOLDER") {
        activeFolderElementId = activeElement.id
    } else if (activeElement?.parentId) {
        activeFolderElementId = activeElement.parentId
    }
    const activeList = elementsData?.list.filter(element => element.parentId === activeFolderElementId || (element.parentId === elementsData?.rootElementId && !activeFolderElementId))
    const rootElement = elementsData?.list.find(element => element.id === elementsData?.rootElementId);
    const elementsBreadcrumbs = findSectionByIdAndItsAncestors(activeFolderElementId, [...elementsData ? elementsData.list : []]).reverse()

    // selecting files will be disabled if one of source element does not have an answer
    const selectedFileDisallowed = props.type === "ELEMENTS" && props.elements.some(element => !element.answer);

    const onCopyHereClick = (element: FormElementV2ResponseDtoExtended) => {
        if (element.storageType === "FOLDER" && props.operation === "COPY") {
            setState(prevState => ({
                ...prevState,
                copyToElement: element
            }));
        } else {
            props.onConfirm(element, null);
        }
    }

    const onConfirmClick = () => {
        onCopyHereClick(activeElement ?? rootElement);
    };

    const onLoanCopyHereClick = async (loan: Loan) => {
        try {
            const loanElements = await getLoanElements({
                id: loan.id,
                view: 'CONVENTIONAL',
                status: 'ACTIVE'
            }).unwrap();

            const loanRootElement = loanElements.list.find(e => !e.parentId && e.storageType === "FOLDER");
            if (loanRootElement) {
                setState(prevState => ({
                    ...prevState,
                    loanId: loan.id
                }));
                onCopyHereClick(loanRootElement);
            } else {
                throw new Error('Root element not found');
            }
        } catch (e) {
            toast.error(e.message);
        }
    }

    const onCancelClick = () => {
        // reset the active element
        setTransition(() => {
            setState({
                activeElement: null,
                loanId: null,
                copyToElement: null,
            })
            props.onCancel();
        });
    }

    const onDialogOpenChange = (open: boolean) => {
        if (!open) {
            onCancelClick();
            setState({
                activeElement: null,
                loanId: null,
                copyToElement: null,
            })
            // set the active element to the parent of the first element
            // if the first element is a file
        }
    }

    const onNewFolderClick = () => {
        const folderElement = elementsData.list.find(e => e.storageType === "FOLDER" && e.id === activeFolderElementId);
        if (folderElement) {
            props.onNewFolder(folderElement);
        }
    }

    const isElementDisabled = (element: FormElementV2ResponseDtoExtended) => {
        const files = props.type === "ELEMENTS" ? props.elements.filter(e => e.storageType === "FILE") : [];
        const folders = props.type === "ELEMENTS" ? props.elements.filter(e => e.storageType === "FOLDER") : [];

        return (selectedFileDisallowed && element.storageType === "FILE") ||
            // do not allow copying moving a file to itself
            files.map(e => e.id).includes(element.id) ||
            // do not allow moving a folder to itself
            (folders.map(e => e.id).includes(element.id) && props.operation === 'MOVE');
    }

    const onSherpaEntitySelect = (sherpaEntityId: string) => {
        props.onConfirm(copyToElement, sherpaEntityId);
        setState(prevState => ({
            ...prevState,
            copyToElement: null
        }));
    }

    const onSelectEntityDialogCancel = () => {
        setState(prevState => ({
            ...prevState,
            copyToElement: null
        }));
    }

    useListener('/elements/folder/created', (event) => {
        setState(prevState => ({
            ...prevState,
            activeElement: event.payload
        }));
    });

    const doesNotHaveSourceElements = props.type === "ELEMENTS" && props.elements.length === 0;
    useLayoutEffect(function setInitialBreadCrumbFolder() {
        // if no element is selected, select the first element
        if (!activeElement && elementsData?.list.length && props.type === "ELEMENTS" && !doesNotHaveSourceElements) {
            const elementInConventional = elementsData.list.find(e => e.id === firstElement?.id);
            const elementParentInConventional = elementsData?.list.find(e => e.id === elementInConventional?.parentId);
            if (elementParentInConventional) {
                setState(prevState => ({
                    ...prevState,
                    activeElement: elementParentInConventional
                }));
            }
        } else if (doesNotHaveSourceElements) {
            setState(prevState => ({
                ...prevState,
                activeElement: null
            }));
        }
    }, [activeElement, elementsData?.list, firstElement?.id, doesNotHaveSourceElements, props.type]);

    const fullBreadcrumbs = [...rootElement ? [rootElement] : [], ...elementsBreadcrumbs];
    // only show last 2 or 3 breadcrumbs depending on operation
    const MAX_BREADCRUMB_ITEMS = props.operation === "COPY" ? 3 : 2;
    const breadcrumbs = fullBreadcrumbs.slice(-MAX_BREADCRUMB_ITEMS);
    const collapsedBreadcrumbs: FormElementV2ResponseDtoExtended[] = fullBreadcrumbs.length > breadcrumbs.length
        ? fullBreadcrumbs.slice(0, fullBreadcrumbs.length - MAX_BREADCRUMB_ITEMS)
        : [];

    return {
        loan,
        loans,
        selectedFileDisallowed,
        rootElement,
        activeFolderElementId,
        activeElement,
        isLoading: isLoadingLoan || isLoadingElements,
        actionText: getActonText(props.operation, activeElement ?? rootElement),
        title: getCopyMoveDialogTitle(props.operation, props.elements),
        disabledElementsIds: props.elements.map(e => e.id),
        collapsedBreadcrumbs,
        list: activeList ?? [],
        loggedInUserId: userState.user.id,
        breadcrumbs,
        activeLoanId: loanId,
        isSelectEntityDialogOpen: !!copyToElement,
        onLoanCopyHereClick,
        onNewFolderClick,
        onElementClick,
        onLoanBreadcrumbClick,
        onDialogOpenChange,
        onCancelClick,
        onConfirmClick,
        onLoanClick,
        isElementDisabled,
        onSherpaEntitySelect,
        onSelectEntityDialogCancel,
        onCopyHereClick,
    } as const;
}

const findParentElement = (elements: FormElementV2ResponseDtoExtended[], element: FormElementV2ResponseDtoExtended) => {
    return elements.find(e => e.id === element.parentId);
}