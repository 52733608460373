import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AppUserDTO2, LoanEntityLabel } from "src/backend";
import { useLoanTemplates } from "src/hooks/use-loan-templates";
import { useAddUserMutation } from "src/services/authApi";
import { useAddLabelsToEntityMutation, useCreateBusinessesMutation, usePostUpSomeBusinessEntityInformationMutation } from "src/services/entityApi";
import { useAddBorrowerUserMutation, useApplyTemplateMutation, useGetLoanByIdQuery } from "src/services/loanApi";
import { Loan } from "src/types/loan";
import { getTemplates } from "src/utils/entity/get-entity-templates";

import { getCopyMoveDialogTitle } from "../copy-move-to-dialog/copy-move-to-dialog.utils";
import { ICopyMoveToSelectEntityDialogProps } from "./copy-move-to-select-entity-dialog.types";
import { copyMoveToSelectEntityValidation } from "./copy-move-to-select-entity-dialog.validation";

interface Inputs {
    sherpaEntityType: 'COMPANY' | 'TRUST' | 'INDIVIDUAL'
    emailAddress: string
    templateId: string
    loanRole: LoanEntityLabel
    name: string
    sherpaEntityId: 'UNKNOWN' | 'NEW' | string
}


export const useCopyMoveToSelectEntity = (props: ICopyMoveToSelectEntityDialogProps) => {
    const [state, setState] = useState<{
        inputValue: string,
        isPopoverOpen: boolean,
    }>({
        inputValue: '',
        isPopoverOpen: false,
    });
    const [addUser] = useAddUserMutation();
    const [addBorrowerUser] = useAddBorrowerUserMutation();
    const [addLabelsToEntity] = useAddLabelsToEntityMutation();
    const [createBusiness] = useCreateBusinessesMutation();
    const [applyTemplate] = useApplyTemplateMutation();
    const [postUpSomeBusinessEntityInformation] = usePostUpSomeBusinessEntityInformationMutation();
    const { data: loan } = useGetLoanByIdQuery(props.loanId);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        watch,
        formState: { errors, isValid, isSubmitting } } = useForm<Inputs>({
            mode: 'onChange',
            resolver: yupResolver(copyMoveToSelectEntityValidation),
            defaultValues: {
                sherpaEntityType: null,
                templateId: '',
                loanRole: null,
                name: '',
                sherpaEntityId: ''
            }
        });

    const [watchSherpaEntityId, watchLoanRole, watchSherpaEntityType, watchTemplateId] = watch(['sherpaEntityId', 'loanRole', 'sherpaEntityType', 'templateId']);

    const setInputValue = (value: string) => {
        setState(prevState => ({ ...prevState, inputValue: value }));
    };

    const setSherpaEntityId = (id: string) => {
        setValue('sherpaEntityId', id);
        setState(prevState => ({
            ...prevState,
            isPopoverOpen: false
        }));
    };

    const setIsPopoverOpen = (isOpen: boolean) => {
        setState(prevState => ({ ...prevState, isPopoverOpen: isOpen }));
    };

    const onAddNewClick = () => {
        setValue('name', state.inputValue);
        setValue('sherpaEntityId', 'NEW');
    }

    const handleAddEntityLabels = async (data: Inputs, sherpaEntityId: string) => {
        return addLabelsToEntity({
            loanId: loan.id,
            sherpaEntityId: sherpaEntityId,
            label: [data.loanRole],
            userLabels: []
        });
    }

    const handleAddUser = async (data: Inputs) => {
        // guest givenName / familyName from name
        const [givenName = '', familyName = ''] = data.name.split(' ');
        return addUser({
            email: data.emailAddress,
            familyName: familyName,
            givenName: givenName,
            positionOrTitle: null,
            mobilePhone: null,
            confirmationCode: null,
            invitationId: null,
            password: null,
            url: null,
            username: null,
        }).unwrap();
    }

    const handleAddBorrowerUser = async (data: Inputs, createdUser: AppUserDTO2) => {
        return addBorrowerUser({
            loanId: loan.id,
            borrowerUser: {
                visibleToBorrower: false,
                visibleToLender: false,
                canAcceptFiles: false,
                contactRelationDto: null,
                emailAddress: null,
                familyName: createdUser.familyName,
                givenName: createdUser.givenName,
                appUser: createdUser.id,
                dob: null,
                borrowerType: data.loanRole !== 'MEMBER' ? 'INDIVIDUAL' : 'MEMBER',
                companyAddress: null,
                companyName: null,
                companyPhone: null,
                companyWebsite: null,
                employments: [],
                residentialAddresses: null,
                citizenshipCountry: null,
                residentialAddress: null,
                passportNumber: null,
                permanentResidenceCardNumber: null,
                permanentResidentAlien: null,
                usCitizen: null,
                placeOfBirth: null,
                maritalStatus: null,
                middleName: null,
                mobilePhone: null,
                positionOrTitle: null,
                role: 'BORROWER',
                sendInvite: false,
                ssn: null,
                templateId: data.templateId,
                driverLicenceInfo: {
                    number: null,
                    expiration: null,
                    stateProvinceRegion: null,
                },
            }
        }).unwrap();
    }

    const onAddIndividual = async (data: Inputs) => {
        try {
            // first check if email address is already on the loan
            // if yes throw an error
            const emailAddressAlreadyOnLoan = loan.loanRoles.findIndex(loanRole => loanRole.user.emailAddress === data.emailAddress) !== -1;
            if (emailAddressAlreadyOnLoan) {
                throw new Error('EMAIL_ADDRESS_ALREADY_ON_LOAN');
            }
            const createdUser = await handleAddUser(data);
            const recentLoan = await handleAddBorrowerUser(data, createdUser);
            const userEntity = recentLoan.loanEntities.find(loanEntity => loanEntity.sherpaEntity.userIndividualId === createdUser.id);
            await handleAddEntityLabels(data, userEntity.sherpaEntity.id);
            props.onSherpaEntitySelect(userEntity.sherpaEntity.id);
        } catch (error) {
            if (error.message === 'EMAIL_ADDRESS_ALREADY_ON_LOAN') {
                setError('emailAddress', { type: 'manual', message: 'Email address is already on the loan.' });
            } else {
                toast.error('Error adding user');
            }
        }
    };

    const onAddBusiness = async (data: Inputs) => {
        try {
            const business = await createBusiness({
                name: data.name,
                type: data.sherpaEntityType,
            }).unwrap();
            await postUpSomeBusinessEntityInformation({
                entityId: business.id,
                information: {
                    entityName: data.name,
                    ein: null,
                    annualSales: null,
                    numberOfEmployees: null,
                    establishedDate: null,
                    companyAddress: null,
                    mailingAddress: null,
                    website: null,
                    mailingSameAsCompanyAddress: null,
                    naics: null,
                    duns: null,
                    emailAddress: null,
                    phoneNumber: null,
                    dbaName: null
                }
            });
            await handleAddEntityLabels(data, business.id);
            await applyTemplate({
                loanId: loan.id,
                templateId: data.templateId,
                entityId: business.id,
            });
            props.onSherpaEntitySelect(business.id);
        } catch (error) {
            console.error('Error adding business:', error);
            toast.error('Error adding Entity');
        }
    }

    const onSubmit = async (data: Inputs) => {
        if (!["UNKNOWN", "NEW"].includes(data.sherpaEntityId)) {
            props.onSherpaEntitySelect(watchSherpaEntityId);
        } else if (data.sherpaEntityId === 'UNKNOWN') {
            props.onSherpaEntitySelect(null);
        } else if (data.sherpaEntityType === 'INDIVIDUAL') {
            await onAddIndividual(data);
        } else {
            await onAddBusiness(data);
        }
    }

    const { applicantEntities, individuals, companies, trusts } = useLoanTemplates();

    const templates = getTemplates({
        entityRole: watchLoanRole,
        type: watchSherpaEntityType,
        applicantEntities: applicantEntities,
        individuals: individuals,
        companies: companies,
        trusts: trusts,
        isAddingEntity: true
    })

    const entitiesList = getEntitiesList(loan?.loanEntities);

    const selectedEntity = entitiesList.find(entity => entity.id === watchSherpaEntityId);

    const filteredEntities = entitiesList.filter(entity =>
        entity.title.toLowerCase().includes(state.inputValue.toLowerCase())
    );

    const onFieldChange = (name: keyof Inputs, value: string) => {
        if (name === 'sherpaEntityType') {
            // reset template id
            setValue('templateId', '');
        }
        setValue(name, value, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true
        });
    }
    return {
        errors,
        loan,
        selectedEntity,
        inputValue: state.inputValue,
        isPopoverOpen: state.isPopoverOpen,
        isAddingNew: watchSherpaEntityId === 'NEW',
        watchSherpaEntityId,
        watchLoanRole,
        watchSherpaEntityType,
        watchTemplateId,
        templates,
        isValid,
        isSubmitting,
        title: getCopyMoveDialogTitle(props.operation, props.elements),
        setInputValue,
        onFieldChange,
        setIsPopoverOpen,
        handleSubmit,
        onSubmit,
        register,
        onAddNewClick,
        filteredEntities,
        setSherpaEntityId,
    } as const;
}

const getEntitiesList = (loanEntities?: Loan['loanEntities']): {
    id: string,
    title: string
}[] => {
    if (!loanEntities) return [];
    const entitiesList = [{
        id: 'UNKNOWN', title: 'I don’t Know'
    }]

    loanEntities.forEach(entity => {
        entitiesList.push({ id: entity.sherpaEntity.id, title: entity.sherpaEntity.name });
    });

    return entitiesList;
}