import Badge from 'components/dist/atoms/Badge';
import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import ScreenSizePopover from 'components/dist/molecules/ScreenSizePopover';
import { useState } from 'react';
import { AppUserDTO2, LoanDto } from 'src/backend';
import { TeamMemberSelectList } from 'src/components/v2/team-member-select-list/team-member-select-list.component';
import { TeamMemberSelectListProps } from 'src/components/v2/team-member-select-list/team-member-select-list.types';
import { useVisibleChildren } from 'src/hooks/useVisibleChildren';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';


interface UsersSelectInputProps extends Pick<LoanDto, 'loanRoles' | 'loanEntities'> {
    onChange: (users: AppUserDTO2[], unAssigned: boolean) => void;
    lenderId: string;
    selectedList?: TeamMemberSelectListProps['assignedList'];
    unassignedChecked: boolean;
}
export const UsersSelectInput = (props: UsersSelectInputProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const visibleChildrenState = useVisibleChildren();
    const hiddenCount = props.selectedList.length - visibleChildrenState.visibleCount;

    return (<ScreenSizePopover

        open={isOpen}
        onOpenChange={setIsOpen}
    >
        <Stack
            items='center'
            row
            className='flex-nowrap overflow-hidden rounded-md border border-gray-neutral-80 px-3 h-10 py-1'>
            <Stack
                row
                items='center'
                className='grid flex-1'>
                <ScreenSizePopover.Trigger
                    className='cursor-text col-start-1 row-start-1'
                    asChild>
                    <Text size='sm' variant='secondary' className='flex-1'>Select</Text>
                </ScreenSizePopover.Trigger>
                <Stack
                    space='sm'
                    row
                    className='overflow-hidden flex-1 items-start justify-start col-start-1 row-start-1 opacity-0'
                    ref={visibleChildrenState.containerRef}>
                    {props.selectedList
                        .map(selectedUser => <BadgeListItem
                            user={selectedUser.user}
                            onRemove={() => props.onChange(
                                props.selectedList.filter(user => user.user.id !== selectedUser.user.id).map(user => user.user)
                                , false)}
                            key={selectedUser.user.id} />)}
                </Stack>
                <Stack
                    space='sm'

                    row
                    className='overflow-hidden flex-1 col-start-1 row-start-1'>
                    {props.selectedList
                        .filter((o, i) => i < visibleChildrenState.visibleCount)
                        .map(selectedUser => <BadgeListItem
                            user={selectedUser.user}
                            onRemove={() => props.onChange(
                                props.selectedList.filter(user => user.user.id !== selectedUser.user.id).map(user => user.user)
                                , false)}
                            key={selectedUser.user.id} />)}
                    {hiddenCount > 0 && <Badge
                        className='shrink-0'
                        variant='secondary'>
                        +{hiddenCount}
                    </Badge>}
                </Stack>
            </Stack>
            <ScreenSizePopover.Trigger asChild>
                <Button
                    variant="ghost"
                    size="sm"
                    spaceX='custom'
                    className='shrink-0'
                >
                    <Icon name="NavArrowDown" width={20} />
                </Button>
            </ScreenSizePopover.Trigger>
        </Stack>
        <ScreenSizePopover.Content
            className='sm:min-w-72 md:min-w-96 py-2 px-0 sm:max-w-96 w-full z-full-screen'
            align="start"
            side="bottom"
            alignOffset={0}>
            <TeamMemberSelectList
                loanRoles={props.loanRoles}
                mode='LOAN'
                selectAllEnabled
                loanEntities={props.loanEntities}
                unassignedChecked={props.unassignedChecked}
                lenderId={props.lenderId}
                onSelect={props.onChange}
                assignedList={props.selectedList}
            />
        </ScreenSizePopover.Content>
    </ScreenSizePopover>)
};

const BadgeListItem = (props: { user: AppUserDTO2, onRemove: () => void }) => {
    return <Badge
        variant='secondary'
        className='shrink-0'
        key={props.user.id} >
        {getUserDisplayName(props.user)}
        <Button
            size='xs'
            variant='ghost'
            onClick={props.onRemove}>
            <Icon name="Cancel" width={18} strokeWidth={1.5} />
        </Button>
    </Badge>
}