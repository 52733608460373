import { j as ce } from "./jsx-runtime-D0AQ1Mn7.js";
import { a as L, _ as x, $ as Ze } from "./index-BmyyIj4u.js";
import * as Ge from "react";
import { forwardRef as E, useContext as qe, createContext as Je, useState as D, useEffect as C, createElement as p, useRef as O, useCallback as Y } from "react";
import { d as F, b as H, $ as pe, a as ae, c as Qe } from "./index-C8YCJLum.js";
import { $ as S, a as et } from "./index-CaNEl95H.js";
import { $ as tt } from "./index-D4lFLFGb.js";
import { R as nt, $ as ot } from "./Combination-C3243Ojg.js";
import { $ as rt } from "./index-DkHhaJ_5.js";
import { u as ct, o as at, s as st, f as it, a as dt, b as ut, h as lt, l as ft, c as pt } from "./floating-ui.react-dom-88_vs_AG.js";
import { $ as wn } from "./index-DyEECGD5.js";
import { $ as $t } from "./index-BLz-1KLb.js";
import bt from "react-dom";
import { $ as $e } from "./index-DMXLf3t8.js";
import { h as vt } from "./index-pK5bN1zh.js";
import { c as mt } from "./utils-CJ9afRe1.js";
const Q = "dismissableLayer.update", ht = "dismissableLayer.pointerDownOutside", gt = "dismissableLayer.focusOutside";
let se;
const Pt = /* @__PURE__ */ Je({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), wt = /* @__PURE__ */ E((e, n) => {
  var t;
  const { disableOutsidePointerEvents: o = !1, onEscapeKeyDown: c, onPointerDownOutside: a, onFocusOutside: s, onInteractOutside: r, onDismiss: u, ...$ } = e, l = qe(Pt), [i, P] = D(null), d = (t = i == null ? void 0 : i.ownerDocument) !== null && t !== void 0 ? t : globalThis == null ? void 0 : globalThis.document, [, y] = D({}), f = L(
    n,
    (h) => P(h)
  ), g = Array.from(l.layers), [b] = [
    ...l.layersWithOutsidePointerEventsDisabled
  ].slice(-1), w = g.indexOf(b), v = i ? g.indexOf(i) : -1, m = l.layersWithOutsidePointerEventsDisabled.size > 0, R = v >= w, Z = xt((h) => {
    const _ = h.target, K = [
      ...l.branches
    ].some(
      (k) => k.contains(_)
    );
    !R || K || (a == null || a(h), r == null || r(h), h.defaultPrevented || u == null || u());
  }, d), M = Et((h) => {
    const _ = h.target;
    [
      ...l.branches
    ].some(
      (k) => k.contains(_)
    ) || (s == null || s(h), r == null || r(h), h.defaultPrevented || u == null || u());
  }, d);
  return tt((h) => {
    v === l.layers.size - 1 && (c == null || c(h), !h.defaultPrevented && u && (h.preventDefault(), u()));
  }, d), C(() => {
    if (i)
      return o && (l.layersWithOutsidePointerEventsDisabled.size === 0 && (se = d.body.style.pointerEvents, d.body.style.pointerEvents = "none"), l.layersWithOutsidePointerEventsDisabled.add(i)), l.layers.add(i), ie(), () => {
        o && l.layersWithOutsidePointerEventsDisabled.size === 1 && (d.body.style.pointerEvents = se);
      };
  }, [
    i,
    d,
    o,
    l
  ]), C(() => () => {
    i && (l.layers.delete(i), l.layersWithOutsidePointerEventsDisabled.delete(i), ie());
  }, [
    i,
    l
  ]), C(() => {
    const h = () => y({});
    return document.addEventListener(Q, h), () => document.removeEventListener(Q, h);
  }, []), /* @__PURE__ */ p(S.div, x({}, $, {
    ref: f,
    style: {
      pointerEvents: m ? R ? "auto" : "none" : void 0,
      ...e.style
    },
    onFocusCapture: F(e.onFocusCapture, M.onFocusCapture),
    onBlurCapture: F(e.onBlurCapture, M.onBlurCapture),
    onPointerDownCapture: F(e.onPointerDownCapture, Z.onPointerDownCapture)
  }));
});
function xt(e, n = globalThis == null ? void 0 : globalThis.document) {
  const t = H(e), o = O(!1), c = O(() => {
  });
  return C(() => {
    const a = (r) => {
      if (r.target && !o.current) {
        let $ = function() {
          be(ht, t, u, {
            discrete: !0
          });
        };
        const u = {
          originalEvent: r
        };
        r.pointerType === "touch" ? (n.removeEventListener("click", c.current), c.current = $, n.addEventListener("click", c.current, {
          once: !0
        })) : $();
      } else
        n.removeEventListener("click", c.current);
      o.current = !1;
    }, s = window.setTimeout(() => {
      n.addEventListener("pointerdown", a);
    }, 0);
    return () => {
      window.clearTimeout(s), n.removeEventListener("pointerdown", a), n.removeEventListener("click", c.current);
    };
  }, [
    n,
    t
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => o.current = !0
  };
}
function Et(e, n = globalThis == null ? void 0 : globalThis.document) {
  const t = H(e), o = O(!1);
  return C(() => {
    const c = (a) => {
      a.target && !o.current && be(gt, t, {
        originalEvent: a
      }, {
        discrete: !1
      });
    };
    return n.addEventListener("focusin", c), () => n.removeEventListener("focusin", c);
  }, [
    n,
    t
  ]), {
    onFocusCapture: () => o.current = !0,
    onBlurCapture: () => o.current = !1
  };
}
function ie() {
  const e = new CustomEvent(Q);
  document.dispatchEvent(e);
}
function be(e, n, t, { discrete: o }) {
  const c = t.originalEvent.target, a = new CustomEvent(e, {
    bubbles: !1,
    cancelable: !0,
    detail: t
  });
  n && c.addEventListener(e, n, {
    once: !0
  }), o ? et(c, a) : c.dispatchEvent(a);
}
const q = "focusScope.autoFocusOnMount", J = "focusScope.autoFocusOnUnmount", de = {
  bubbles: !1,
  cancelable: !0
}, Ot = /* @__PURE__ */ E((e, n) => {
  const { loop: t = !1, trapped: o = !1, onMountAutoFocus: c, onUnmountAutoFocus: a, ...s } = e, [r, u] = D(null), $ = H(c), l = H(a), i = O(null), P = L(
    n,
    (f) => u(f)
  ), d = O({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  C(() => {
    if (o) {
      let f = function(v) {
        if (d.paused || !r) return;
        const m = v.target;
        r.contains(m) ? i.current = m : A(i.current, {
          select: !0
        });
      }, g = function(v) {
        if (d.paused || !r) return;
        const m = v.relatedTarget;
        m !== null && (r.contains(m) || A(i.current, {
          select: !0
        }));
      }, b = function(v) {
        if (document.activeElement === document.body)
          for (const R of v) R.removedNodes.length > 0 && A(r);
      };
      document.addEventListener("focusin", f), document.addEventListener("focusout", g);
      const w = new MutationObserver(b);
      return r && w.observe(r, {
        childList: !0,
        subtree: !0
      }), () => {
        document.removeEventListener("focusin", f), document.removeEventListener("focusout", g), w.disconnect();
      };
    }
  }, [
    o,
    r,
    d.paused
  ]), C(() => {
    if (r) {
      le.add(d);
      const f = document.activeElement;
      if (!r.contains(f)) {
        const b = new CustomEvent(q, de);
        r.addEventListener(q, $), r.dispatchEvent(b), b.defaultPrevented || (yt(Ft(ve(r)), {
          select: !0
        }), document.activeElement === f && A(r));
      }
      return () => {
        r.removeEventListener(q, $), setTimeout(() => {
          const b = new CustomEvent(J, de);
          r.addEventListener(J, l), r.dispatchEvent(b), b.defaultPrevented || A(f ?? document.body, {
            select: !0
          }), r.removeEventListener(J, l), le.remove(d);
        }, 0);
      };
    }
  }, [
    r,
    $,
    l,
    d
  ]);
  const y = Y((f) => {
    if (!t && !o || d.paused) return;
    const g = f.key === "Tab" && !f.altKey && !f.ctrlKey && !f.metaKey, b = document.activeElement;
    if (g && b) {
      const w = f.currentTarget, [v, m] = Ct(w);
      v && m ? !f.shiftKey && b === m ? (f.preventDefault(), t && A(v, {
        select: !0
      })) : f.shiftKey && b === v && (f.preventDefault(), t && A(m, {
        select: !0
      })) : b === w && f.preventDefault();
    }
  }, [
    t,
    o,
    d.paused
  ]);
  return /* @__PURE__ */ p(S.div, x({
    tabIndex: -1
  }, s, {
    ref: P,
    onKeyDown: y
  }));
});
function yt(e, { select: n = !1 } = {}) {
  const t = document.activeElement;
  for (const o of e)
    if (A(o, {
      select: n
    }), document.activeElement !== t) return;
}
function Ct(e) {
  const n = ve(e), t = ue(n, e), o = ue(n.reverse(), e);
  return [
    t,
    o
  ];
}
function ve(e) {
  const n = [], t = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (o) => {
      const c = o.tagName === "INPUT" && o.type === "hidden";
      return o.disabled || o.hidden || c ? NodeFilter.FILTER_SKIP : o.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; t.nextNode(); ) n.push(t.currentNode);
  return n;
}
function ue(e, n) {
  for (const t of e)
    if (!_t(t, {
      upTo: n
    })) return t;
}
function _t(e, { upTo: n }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (n !== void 0 && e === n) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function At(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function A(e, { select: n = !1 } = {}) {
  if (e && e.focus) {
    const t = document.activeElement;
    e.focus({
      preventScroll: !0
    }), e !== t && At(e) && n && e.select();
  }
}
const le = Dt();
function Dt() {
  let e = [];
  return {
    add(n) {
      const t = e[0];
      n !== t && (t == null || t.pause()), e = fe(e, n), e.unshift(n);
    },
    remove(n) {
      var t;
      e = fe(e, n), (t = e[0]) === null || t === void 0 || t.resume();
    }
  };
}
function fe(e, n) {
  const t = [
    ...e
  ], o = t.indexOf(n);
  return o !== -1 && t.splice(o, 1), t;
}
function Ft(e) {
  return e.filter(
    (n) => n.tagName !== "A"
  );
}
const me = "Popper", [he, ge] = pe(me), [Tt, Pe] = he(me), Rt = (e) => {
  const { __scopePopper: n, children: t } = e, [o, c] = D(null);
  return /* @__PURE__ */ p(Tt, {
    scope: n,
    anchor: o,
    onAnchorChange: c
  }, t);
}, St = "PopperAnchor", It = /* @__PURE__ */ E((e, n) => {
  const { __scopePopper: t, virtualRef: o, ...c } = e, a = Pe(St, t), s = O(null), r = L(n, s);
  return C(() => {
    a.onAnchorChange((o == null ? void 0 : o.current) || s.current);
  }), o ? null : /* @__PURE__ */ p(S.div, x({}, c, {
    ref: r
  }));
}), we = "PopperContent", [Nt, _n] = he(we), Lt = /* @__PURE__ */ E((e, n) => {
  var t, o, c, a, s, r, u, $;
  const { __scopePopper: l, side: i = "bottom", sideOffset: P = 0, align: d = "center", alignOffset: y = 0, arrowPadding: f = 0, avoidCollisions: g = !0, collisionBoundary: b = [], collisionPadding: w = 0, sticky: v = "partial", hideWhenDetached: m = !1, updatePositionStrategy: R = "optimized", onPlaced: Z, ...M } = e, h = Pe(we, l), [_, K] = D(null), k = L(
    n,
    (W) => K(W)
  ), [G, Re] = D(null), I = $t(G), Se = (t = I == null ? void 0 : I.width) !== null && t !== void 0 ? t : 0, ee = (o = I == null ? void 0 : I.height) !== null && o !== void 0 ? o : 0, Ie = i + (d !== "center" ? "-" + d : ""), Ne = typeof w == "number" ? w : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...w
  }, te = Array.isArray(b) ? b : [
    b
  ], Le = te.length > 0, z = {
    padding: Ne,
    boundary: te.filter(Mt),
    // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
    altBoundary: Le
  }, { refs: Me, floatingStyles: ne, placement: ke, isPositioned: B, middlewareData: N } = ct({
    // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
    strategy: "fixed",
    placement: Ie,
    whileElementsMounted: (...W) => pt(...W, {
      animationFrame: R === "always"
    }),
    elements: {
      reference: h.anchor
    },
    middleware: [
      at({
        mainAxis: P + ee,
        alignmentAxis: y
      }),
      g && st({
        mainAxis: !0,
        crossAxis: !1,
        limiter: v === "partial" ? ft() : void 0,
        ...z
      }),
      g && it({
        ...z
      }),
      dt({
        ...z,
        apply: ({ elements: W, rects: re, availableWidth: je, availableHeight: Xe }) => {
          const { width: Ye, height: Ve } = re.reference, X = W.floating.style;
          X.setProperty("--radix-popper-available-width", `${je}px`), X.setProperty("--radix-popper-available-height", `${Xe}px`), X.setProperty("--radix-popper-anchor-width", `${Ye}px`), X.setProperty("--radix-popper-anchor-height", `${Ve}px`);
        }
      }),
      G && ut({
        element: G,
        padding: f
      }),
      kt({
        arrowWidth: Se,
        arrowHeight: ee
      }),
      m && lt({
        strategy: "referenceHidden",
        ...z
      })
    ]
  }), [oe, We] = xe(ke), j = H(Z);
  ae(() => {
    B && (j == null || j());
  }, [
    B,
    j
  ]);
  const He = (c = N.arrow) === null || c === void 0 ? void 0 : c.x, Ue = (a = N.arrow) === null || a === void 0 ? void 0 : a.y, Ke = ((s = N.arrow) === null || s === void 0 ? void 0 : s.centerOffset) !== 0, [ze, Be] = D();
  return ae(() => {
    _ && Be(window.getComputedStyle(_).zIndex);
  }, [
    _
  ]), /* @__PURE__ */ p("div", {
    ref: Me.setFloating,
    "data-radix-popper-content-wrapper": "",
    style: {
      ...ne,
      transform: B ? ne.transform : "translate(0, -200%)",
      // keep off the page when measuring
      minWidth: "max-content",
      zIndex: ze,
      "--radix-popper-transform-origin": [
        (r = N.transformOrigin) === null || r === void 0 ? void 0 : r.x,
        (u = N.transformOrigin) === null || u === void 0 ? void 0 : u.y
      ].join(" ")
    },
    dir: e.dir
  }, /* @__PURE__ */ p(Nt, {
    scope: l,
    placedSide: oe,
    onArrowChange: Re,
    arrowX: He,
    arrowY: Ue,
    shouldHideArrow: Ke
  }, /* @__PURE__ */ p(S.div, x({
    "data-side": oe,
    "data-align": We
  }, M, {
    ref: k,
    style: {
      ...M.style,
      // if the PopperContent hasn't been placed yet (not all measurements done)
      // we prevent animations so that users's animation don't kick in too early referring wrong sides
      animation: B ? void 0 : "none",
      // hide the content if using the hide middleware and should be hidden
      opacity: ($ = N.hide) !== null && $ !== void 0 && $.referenceHidden ? 0 : void 0
    }
  }))));
});
function Mt(e) {
  return e !== null;
}
const kt = (e) => ({
  name: "transformOrigin",
  options: e,
  fn(n) {
    var t, o, c, a, s;
    const { placement: r, rects: u, middlewareData: $ } = n, i = ((t = $.arrow) === null || t === void 0 ? void 0 : t.centerOffset) !== 0, P = i ? 0 : e.arrowWidth, d = i ? 0 : e.arrowHeight, [y, f] = xe(r), g = {
      start: "0%",
      center: "50%",
      end: "100%"
    }[f], b = ((o = (c = $.arrow) === null || c === void 0 ? void 0 : c.x) !== null && o !== void 0 ? o : 0) + P / 2, w = ((a = (s = $.arrow) === null || s === void 0 ? void 0 : s.y) !== null && a !== void 0 ? a : 0) + d / 2;
    let v = "", m = "";
    return y === "bottom" ? (v = i ? g : `${b}px`, m = `${-d}px`) : y === "top" ? (v = i ? g : `${b}px`, m = `${u.floating.height + d}px`) : y === "right" ? (v = `${-d}px`, m = i ? g : `${w}px`) : y === "left" && (v = `${u.floating.width + d}px`, m = i ? g : `${w}px`), {
      data: {
        x: v,
        y: m
      }
    };
  }
});
function xe(e) {
  const [n, t = "center"] = e.split("-");
  return [
    n,
    t
  ];
}
const Wt = Rt, Ee = It, Ht = Lt, Ut = /* @__PURE__ */ E((e, n) => {
  var t;
  const { container: o = globalThis == null || (t = globalThis.document) === null || t === void 0 ? void 0 : t.body, ...c } = e;
  return o ? /* @__PURE__ */ bt.createPortal(/* @__PURE__ */ p(S.div, x({}, c, {
    ref: n
  })), o) : null;
}), Oe = "Popover", [ye, An] = pe(Oe, [
  ge
]), V = ge(), [Kt, T] = ye(Oe), zt = (e) => {
  const { __scopePopover: n, children: t, open: o, defaultOpen: c, onOpenChange: a, modal: s = !1 } = e, r = V(n), u = O(null), [$, l] = D(!1), [i = !1, P] = Qe({
    prop: o,
    defaultProp: c,
    onChange: a
  });
  return /* @__PURE__ */ p(Wt, r, /* @__PURE__ */ p(Kt, {
    scope: n,
    contentId: rt(),
    triggerRef: u,
    open: i,
    onOpenChange: P,
    onOpenToggle: Y(
      () => P(
        (d) => !d
      ),
      [
        P
      ]
    ),
    hasCustomAnchor: $,
    onCustomAnchorAdd: Y(
      () => l(!0),
      []
    ),
    onCustomAnchorRemove: Y(
      () => l(!1),
      []
    ),
    modal: s
  }, t));
}, Bt = "PopoverAnchor", jt = /* @__PURE__ */ E((e, n) => {
  const { __scopePopover: t, ...o } = e, c = T(Bt, t), a = V(t), { onCustomAnchorAdd: s, onCustomAnchorRemove: r } = c;
  return C(() => (s(), () => r()), [
    s,
    r
  ]), /* @__PURE__ */ p(Ee, x({}, a, o, {
    ref: n
  }));
}), Xt = "PopoverTrigger", Yt = /* @__PURE__ */ E((e, n) => {
  const { __scopePopover: t, ...o } = e, c = T(Xt, t), a = V(t), s = L(n, c.triggerRef), r = /* @__PURE__ */ p(S.button, x({
    type: "button",
    "aria-haspopup": "dialog",
    "aria-expanded": c.open,
    "aria-controls": c.contentId,
    "data-state": Ae(c.open)
  }, o, {
    ref: s,
    onClick: F(e.onClick, c.onOpenToggle)
  }));
  return c.hasCustomAnchor ? r : /* @__PURE__ */ p(Ee, x({
    asChild: !0
  }, a), r);
}), Ce = "PopoverPortal", [Vt, Zt] = ye(Ce, {
  forceMount: void 0
}), Gt = (e) => {
  const { __scopePopover: n, forceMount: t, children: o, container: c } = e, a = T(Ce, n);
  return /* @__PURE__ */ p(Vt, {
    scope: n,
    forceMount: t
  }, /* @__PURE__ */ p($e, {
    present: t || a.open
  }, /* @__PURE__ */ p(Ut, {
    asChild: !0,
    container: c
  }, o)));
}, U = "PopoverContent", qt = /* @__PURE__ */ E((e, n) => {
  const t = Zt(U, e.__scopePopover), { forceMount: o = t.forceMount, ...c } = e, a = T(U, e.__scopePopover);
  return /* @__PURE__ */ p($e, {
    present: o || a.open
  }, a.modal ? /* @__PURE__ */ p(Jt, x({}, c, {
    ref: n
  })) : /* @__PURE__ */ p(Qt, x({}, c, {
    ref: n
  })));
}), Jt = /* @__PURE__ */ E((e, n) => {
  const t = T(U, e.__scopePopover), o = O(null), c = L(n, o), a = O(!1);
  return C(() => {
    const s = o.current;
    if (s) return vt(s);
  }, []), /* @__PURE__ */ p(nt, {
    as: Ze,
    allowPinchZoom: !0
  }, /* @__PURE__ */ p(_e, x({}, e, {
    ref: c,
    trapFocus: t.open,
    disableOutsidePointerEvents: !0,
    onCloseAutoFocus: F(e.onCloseAutoFocus, (s) => {
      var r;
      s.preventDefault(), a.current || (r = t.triggerRef.current) === null || r === void 0 || r.focus();
    }),
    onPointerDownOutside: F(e.onPointerDownOutside, (s) => {
      const r = s.detail.originalEvent, u = r.button === 0 && r.ctrlKey === !0, $ = r.button === 2 || u;
      a.current = $;
    }, {
      checkForDefaultPrevented: !1
    }),
    onFocusOutside: F(
      e.onFocusOutside,
      (s) => s.preventDefault(),
      {
        checkForDefaultPrevented: !1
      }
    )
  })));
}), Qt = /* @__PURE__ */ E((e, n) => {
  const t = T(U, e.__scopePopover), o = O(!1), c = O(!1);
  return /* @__PURE__ */ p(_e, x({}, e, {
    ref: n,
    trapFocus: !1,
    disableOutsidePointerEvents: !1,
    onCloseAutoFocus: (a) => {
      var s;
      if ((s = e.onCloseAutoFocus) === null || s === void 0 || s.call(e, a), !a.defaultPrevented) {
        var r;
        o.current || (r = t.triggerRef.current) === null || r === void 0 || r.focus(), a.preventDefault();
      }
      o.current = !1, c.current = !1;
    },
    onInteractOutside: (a) => {
      var s, r;
      (s = e.onInteractOutside) === null || s === void 0 || s.call(e, a), a.defaultPrevented || (o.current = !0, a.detail.originalEvent.type === "pointerdown" && (c.current = !0));
      const u = a.target;
      ((r = t.triggerRef.current) === null || r === void 0 ? void 0 : r.contains(u)) && a.preventDefault(), a.detail.originalEvent.type === "focusin" && c.current && a.preventDefault();
    }
  }));
}), _e = /* @__PURE__ */ E((e, n) => {
  const { __scopePopover: t, trapFocus: o, onOpenAutoFocus: c, onCloseAutoFocus: a, disableOutsidePointerEvents: s, onEscapeKeyDown: r, onPointerDownOutside: u, onFocusOutside: $, onInteractOutside: l, ...i } = e, P = T(U, t), d = V(t);
  return ot(), /* @__PURE__ */ p(Ot, {
    asChild: !0,
    loop: !0,
    trapped: o,
    onMountAutoFocus: c,
    onUnmountAutoFocus: a
  }, /* @__PURE__ */ p(wt, {
    asChild: !0,
    disableOutsidePointerEvents: s,
    onInteractOutside: l,
    onEscapeKeyDown: r,
    onPointerDownOutside: u,
    onFocusOutside: $,
    onDismiss: () => P.onOpenChange(!1)
  }, /* @__PURE__ */ p(Ht, x({
    "data-state": Ae(P.open),
    role: "dialog",
    id: P.contentId
  }, d, i, {
    ref: n,
    style: {
      ...i.style,
      "--radix-popover-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-popover-content-available-width": "var(--radix-popper-available-width)",
      "--radix-popover-content-available-height": "var(--radix-popper-available-height)",
      "--radix-popover-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-popover-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }))));
}), en = "PopoverClose", tn = /* @__PURE__ */ E((e, n) => {
  const { __scopePopover: t, ...o } = e, c = T(en, t);
  return /* @__PURE__ */ p(S.button, x({
    type: "button"
  }, o, {
    ref: n,
    onClick: F(
      e.onClick,
      () => c.onOpenChange(!1)
    )
  }));
});
function Ae(e) {
  return e ? "open" : "closed";
}
const nn = zt, on = jt, rn = Yt, De = Gt, Fe = qt, cn = tn, an = nn, sn = rn, dn = on, un = cn, Te = Ge.forwardRef(({ className: e, withPortal: n, align: t = "center", sideOffset: o = 4, ...c }, a) => {
  const s = /* @__PURE__ */ ce.jsx(
    Fe,
    {
      ref: a,
      align: t,
      sideOffset: o,
      className: mt(
        "z-tooltip w-72 rounded-md border border-gray-neutral-80 bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        e
      ),
      ...c
    }
  );
  return n ? /* @__PURE__ */ ce.jsx(De, { children: s }) : s;
});
Te.displayName = Fe.displayName;
const Dn = Object.assign(an, {
  Trigger: sn,
  Anchor: dn,
  Content: Te,
  Portal: De,
  Close: un
});
export {
  Dn as P
};
