import { FormikHelpers, useFormik } from "formik";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { AppUserDTO2, BorrowUserSetDto, ContactRelationAddDto, ContactSubRoleDto } from "src/backend";
import { Route } from "src/constants/routing";
import { useAuth } from "src/hooks/use-auth";
import { useHasValuesCallback } from "src/hooks/use-has-values-callback";
import { useLoanTemplates } from "src/hooks/use-loan-templates";
import { useV2TemplateElements } from "src/hooks/use-v2-template-elements";
import { useAddContactMutation, useEditContactMutation } from "src/services/contactApi";
import { useEditNonLenderUserMutation, useGetLoanByIdQuery, useLazyGetLoanByIdQuery } from "src/services/loanApi";
import { useGetLoggedInUserQuery } from "src/services/userApi";
import { getLoan } from "src/slices/loan";
import { useDispatch } from "src/store";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

import { ContactFormProps, ContactFormValues } from "./contact-form.types";
import { ContactFormValidation } from "./contact-form.validation";


const initialValues: ContactFormValues = {
    saveType: 'SAVE',
    userId: '',
    givenName: '',
    loanId: '',
    familyName: '',
    emailAddress: '',
    phoneNumber: {
        locale: 'US',
        value: '',
    },
    companyAddress: {
        citySuburb: '',
        country: '',
        currentAddress: true,
        moveInDate: '',
        moveOutDate: '',
        postOfficeBox: '',
        postalCode: '',
        stateProvinceRegion: '',
        streetAddressLine1: '',
        streetAddressLine2: '',
    },
    keyContact: false,
    companyName: '',
    companyWebsite: '',
    positionOrTitle: '',
    contactSubRoleId: '',
    contactSubRoleType: 'NONE',
    visibleToBorrower: false,
    visibleToLender: false,
    emailEditingDisabled: false,
    editOtherFieldsDisabled: false,
    contactSubRoleTitle: '',
    templateId: '',
    fieldsVisible: false,
}
export const useContactFormState = (props: ContactFormProps) => {
    const { data: loanData } = useGetLoanByIdQuery(props.loanId);
    const [getLoanById] = useLazyGetLoanByIdQuery();
    const [addContact] = useAddContactMutation();
    const [editContact] = useEditContactMutation();
    const router = useRouter();
    const { user } = useAuth();
    const { data: { viewType } } = useGetLoggedInUserQuery()
    const [editNonLenderUser] = useEditNonLenderUserMutation();
    const dispatch = useDispatch();
    const { applicantEntities } = useLoanTemplates();

    const handleAfterSubmit = (type: ContactFormValues['saveType']) => {
        // if save type is save and close
        // close and redirect back to loan
        if (['SAVE_AND_CLOSE', 'SAVE_AND_NEW'].includes(type)) {
            formik.resetForm({
                values: initialValues
            })
        }
        if (type === 'SAVE_AND_CLOSE') {
            router.push({
                pathname: Route.SINGLE_LOAN,
                query: {
                    ...router.query,
                    loanId: loanData.id,
                }
            })
            // if save type is save and new
            // and role type is applicant
            // we redirect to create new applicant
        } else if (type === 'SAVE_AND_NEW') {
            router.push({
                pathname: Route.LOAN_CONTACT_KEY_CONTACT_CREATE,
                query: {
                    ...router.query,
                    loanId: loanData.id,
                }
            });
        }
    }

    const onSubmit = async (values: ContactFormValues, helpers: FormikHelpers<ContactFormValues>) => {
        try {
            helpers.setSubmitting(true);
            const iRelation: ContactRelationAddDto = {
                id: null,
                visibleToBorrower: values.visibleToBorrower && values.keyContact,
                visibleToLender: values.visibleToLender,
                userContactId: values.userId,
                contactSubRoleId: values.contactSubRoleId,
                userContactEmailAddress: values.emailAddress,
                familyName: values.familyName,
                givenName: values.givenName,
                keyContact: values.keyContact,
                positionOrTitle: values.positionOrTitle,
                companyAddress: values.companyAddress,
                companyName: values.companyName,
                companyWebsite: values.companyWebsite,
            }
            // if we have props.loanRole, we are editing an existing contact
            if (props.initialValues?.userId) {
                const contactLoanRole = loanData.loanRoles.find(loanRole => loanRole.user.id === props.initialValues.userId);
                const payload: Partial<BorrowUserSetDto> = {
                    borrowerType: values.contactSubRoleType === 'BORROWER' ? 'INDIVIDUAL' : 'MEMBER',
                    role: ['BORROWER', 'MEMBER'].includes(values.contactSubRoleType) ? 'BORROWER' : 'CONTACT',
                    familyName: values.familyName,
                    givenName: values.givenName,
                    positionOrTitle: values.positionOrTitle,
                    companyName: values.companyName,
                    companyWebsite: values.companyWebsite,
                    companyAddress: values.companyAddress,
                    templateId: values.templateId,
                    contactRelationDto: {
                        id: null,
                        contactSubRoleId: null,
                        companyName: values.companyName,
                        companyWebsite: values.companyWebsite,
                        companyAddress: values.companyAddress,
                        familyName: values.familyName,
                        givenName: values.givenName,
                        keyContact: values.keyContact,
                        positionOrTitle: values.positionOrTitle,
                        userContactEmailAddress: values.emailAddress,
                        userContactId: values.userId,
                        visibleToBorrower: contactLoanRole.visibleToBorrower,
                        visibleToLender: true
                    }
                }
                await editNonLenderUser({
                    loanId: props.loanId,
                    userId: values.userId,
                    iDto: payload,
                }).unwrap();
                const promises = contactLoanRole.contactRelations.map(contactRelation => editContact({
                    loanId: props.loanId,
                    contactLoanRoleId: contactLoanRole.id,
                    iRelation: {
                        id: contactRelation.id,
                        visibleToBorrower: values.visibleToBorrower && values.keyContact,
                        visibleToLender: values.visibleToLender,
                        userContactId: values.userId,
                        contactSubRoleId: values.contactSubRoleId,
                        userContactEmailAddress: values.emailAddress,
                        familyName: values.familyName,
                        givenName: values.givenName,
                        keyContact: values.keyContact,
                        positionOrTitle: values.positionOrTitle,
                        companyAddress: values.companyAddress,
                        companyName: values.companyName,
                        companyWebsite: values.companyWebsite,
                    }
                }));
                await Promise.allSettled(promises);
                await editContact({
                    loanId: props.loanId,
                    iRelation: iRelation,
                    contactLoanRoleId: contactLoanRole.id
                })
                if (!props.initialValues.keyContact && iRelation.keyContact) {
                    toast.success(`${getUserDisplayName(values)} updated and moved to key contact successfully`);
                } else {
                    toast.success(`${getUserDisplayName(values)} updated successfully`);
                }
            } else {
                // if we have another contact with the same email address, we need to show an error
                if (loanData.loanRoles.some(loanRole => loanRole.user.emailAddress === values.emailAddress)) {
                    toast.error('A contact with the same email address already exists');
                    helpers.setSubmitting(false);
                    return;
                }
                const contactRelation = await addContact({
                    loanId: props.loanId,
                    userId: values.userId,
                    loanRoleCurrentUserId: props.ownerRole.id,
                    iRelation: iRelation
                }).unwrap();
                if (values.keyContact) {
                    toast.success(`${getUserDisplayName(values)} added as key contact successfully`)
                } else {
                    toast.success(`${getUserDisplayName(values)} added to the loan and your profile as a contact successfully`);
                }
                dispatch(getLoan(props.loanId, false, true));
                const loan = await getLoanById(props.loanId).unwrap();
                // find new loan role for created contact
                const loanRole = loan.loanRoles.find(loanRole => loanRole.user.id === contactRelation.contactUser.id);
                helpers.setSubmitting(false);
                if (values.saveType === 'SAVE') {
                    router.push({
                        pathname: Route.LOAN_CONTACT_KEY_CONTACT_EDIT,
                        query: {
                            ...router.query,
                            loanId: loanData.id,
                            loanRoleId: loanRole.id,
                        }
                    })
                }
            }
            handleAfterSubmit(values.saveType);
        } catch (error) {
            toast.error('Error adding contact');
            console.error(error);
        }
    }
    const formik = useFormik<ContactFormValues>({
        validationSchema: ContactFormValidation,
        initialValues: {
            ...initialValues,
            ...props.initialValues,
            fieldsVisible: !!props.initialValues?.userId,
        },
        onSubmit
    });

    const onContactRoleSelected = (contactSubRole: ContactSubRoleDto) => {
        formik.setFieldValue('contactSubRoleId', contactSubRole.id, true);
        formik.setFieldValue('contactSubRoleType', contactSubRole.subRoleType, true);
        if (contactSubRole.subRoleType !== 'BORROWER') {
            formik.setFieldValue('templateId', '', true);
        }
    };
    const onUserSelected = (user: AppUserDTO2) => {
        if (user) {
            formik.setValues(values => ({
                ...values,
                userId: user.id,
                givenName: user.givenName ?? '',
                familyName: user.familyName ?? '',
                emailAddress: user.emailAddress ?? '',
                phoneNumber: {
                    locale: 'US',
                    value: user.mobilePhone?.value ?? '',
                },
                companyAddress: user.companyAddress,
                companyName: user.companyName ?? '',
                companyWebsite: user.companyWebsite ?? '',
                positionOrTitle: user.positionOrTitle ?? '',
                loanRole: null,
                visibleToBorrower: false,
                emailEditingDisabled: !!user.id,
                contactSubRoleType: 'NONE',
                editOtherFieldsDisabled: props.hideKeyContact && !!user.emailAddress,
                fieldsVisible: true,
            }));
        } else {
            formik.setValues(initialValues);
        }
    };
    const hasUnsavedChanges = useHasValuesCallback(formik.values, (formik.dirty && !formik.isSubmitting));

    const templateState = useV2TemplateElements({
        templateId: formik.values.templateId,
        reviewStatus: loanData?.reviewStatus
    });

    const handleCheckFieldDisabled = (field: string) => {
        if (field === 'emailAddress') {
            return formik.values.emailEditingDisabled
        } else if (field === 'phoneNumber.value') {
            return !isEmpty(formik.initialValues?.phoneNumber?.value) &&
                formik.values.editOtherFieldsDisabled
        } else if (field === 'companyAddress') {
            return !isEmpty(formik.initialValues?.companyAddress?.streetAddressLine1) &&
                formik.values.editOtherFieldsDisabled
        }

        return !isEmpty(formik.initialValues[field]) && formik.values.editOtherFieldsDisabled
    };

    const onAddNewClick = () => {
        const loggedInUserLoanRole = loanData.loanRoles.find(loanRole => loanRole.user.id === user.id);
        props.onAddNewClick?.(loggedInUserLoanRole);
    }

    const onSaveAndNew = async () => {
        await formik.setFieldValue('saveType', 'SAVE_AND_NEW', true);
        formik.submitForm();
    }

    const onSaveClose = async () => {
        await formik.setFieldValue('saveType', 'SAVE_AND_CLOSE', true);
        formik.submitForm();
    };

    return {
        formik,
        isLoggedInUserALender: viewType === 'LENDER',
        templates: applicantEntities.filter(template => template.sherpaEntityType === 'INDIVIDUAL'),
        hasUnsavedChanges,
        loanData,
        elementsTree: templateState.elementsTree,
        totalFileRequests: templateState.totalFileRequests,
        totalHiddenFileRequests: templateState.totalHiddenFileRequests,
        ids: templateState.ids,
        onSaveAndNew,
        onSaveClose,
        onUserSelected,
        onContactRoleSelected,
        onAddNewClick,
        isFieldDisabled: handleCheckFieldDisabled
    } as const;
};

const isEmpty = (value: string | undefined) => !value || value.trim().length === 0;