import Button from "components/dist/atoms/Button"
import Dialog from "components/dist/atoms/Dialog"
import Icon from "components/dist/atoms/Icon"
import Input from "components/dist/atoms/Input"
import Separator from "components/dist/atoms/Separator";

interface Props {
    open: boolean;
    isLoading: boolean;
    onOpenChange: (open: boolean) => void;
    onFolderNameChange: (folderName: string) => void;
    onConfirm: () => void;
}

export const NewFolderDialog = (props: Props) => {
    return <Dialog
        open={props.open}
        onOpenChange={props.onOpenChange}>
        <Dialog.Content
            overlayClassName="z-full-screen"
            className="gap-0 rounded max-w-[95%] z-full-screen">
            <Dialog.Title className="flex gap-2 items-center p-3">
                <Icon name="ClientFolder" width={20} height={20} className="text-yellow-60" /> New Folder
            </Dialog.Title>
            <Separator />
            <div className="p-3">
                <Input
                    disabled={props.isLoading}
                    onChange={e => props.onFolderNameChange(e.target.value)}
                    placeholder="Enter Folder Name" />
            </div>
            <Dialog.Footer className="flex-row justify-between">
                <Dialog.Close asChild>
                    <Button
                        disabled={props.isLoading}
                        variant="outline" className="w-24">
                        Cancel
                    </Button>
                </Dialog.Close>
                <Button
                    loading={props.isLoading}
                    onClick={props.onConfirm}
                    className="w-24">
                    Create
                </Button>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>
}