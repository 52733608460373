import { useRouter } from "next/router";
import { Route } from "src/constants/routing";
import { removeRoleFromLoan } from "src/slices/loan";
import { useDispatch } from "src/store";

export const useRoleActionsMenuHook = () => {
    const dispatch = useDispatch();
    const router = useRouter();

    const handleDelete = async ({ roleId, loanId }: { roleId: string, loanId: string }) => {
        await dispatch(removeRoleFromLoan(loanId, roleId));
    };

    const handleEdit = async ({ id, loanId, type }: { id: string, loanId: string, type: "PERSON" | "ROLE" }) => {
        if (type === "PERSON") {
            router.push({
                pathname: Route.EDIT_LOAN_PRINCIPAL,
                query: {
                    ...router.query,
                    loanId,
                    personId: id,
                }
            })
        } else {
            router.push({
                pathname: Route.EDIT_LOAN_LENDER_ROLE,
                query: {
                    ...router.query,
                    loanId,
                    roleId: id
                }
            })
        }
    };

    return {
        onDelete: handleDelete,
        onEdit: handleEdit,
    }
}