import { LoanEntityLabel } from 'src/backend';
import * as yup from 'yup';

export const copyMoveToSelectEntityValidation = yup
    .object({
        sherpaEntityType: yup.mixed<'COMPANY' | 'TRUST' | 'INDIVIDUAL'>()
            .when('sherpaEntityId', {
                is: (val) => val === 'NEW',
                then: yup.mixed<'COMPANY' | 'TRUST' | 'INDIVIDUAL'>().oneOf(['COMPANY', 'TRUST', 'INDIVIDUAL']).required(),
                otherwise: yup.string().nullable()
            }),
        templateId: yup.string()
            .when('sherpaEntityId', {
                is: (val) => val === 'NEW',
                then: yup.string().required(),
                otherwise: yup.string()
            }),
        loanRole: yup.mixed<LoanEntityLabel>()
            .when('sherpaEntityId', {
                is: (val) => val === 'NEW',
                then: yup.mixed<LoanEntityLabel>().required(),
                otherwise: yup.string().nullable()
            }),
        name: yup.string()
            .when('sherpaEntityId', {
                is: (val) => val === 'NEW',
                then: yup.string().required(),
                otherwise: yup.string()
            }),
        emailAddress: yup.string().email()
            .when('sherpaEntityType', {
                is: 'INDIVIDUAL',
                then: yup.string().email().required(),
                otherwise: yup.string().nullable()
            }),
        sherpaEntityId: yup.mixed<'UNKNOWN' | 'NEW' | string>().required(),
    }).required();
