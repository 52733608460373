import Badge from 'components/dist/atoms/Badge';
import Button from 'components/dist/atoms/Button';
import Checkbox from 'components/dist/atoms/Checkbox';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import UserNameText from 'components/dist/molecules/UserNameText';
import { pluralize } from 'src/utils/pluralize';

import { useAssignFolderDroppedItems } from './assign-folder-dropped-items.state';
import { AssignFolderDroppedItemsProps } from './assign-folder-dropped-items.types';

export const AssignFolderDroppedItemsDialog = (props: AssignFolderDroppedItemsProps) => {
    const state = useAssignFolderDroppedItems(props);

    return <Dialog
        open
        onOpenChange={props.onCancel}
    >
        <Dialog.Content
            onOpenAutoFocus={(e) => e.preventDefault()}
            className='lg:max-w-3xl w-full sm:max-w-[95%] max-w-[95%] rounded-md gap-0'>
            <Dialog.Title className='font-normal text-sm flex flex-row gap-2 items-center'>
                <Icon name="BigExclamationDestructive" width={32} height={32} />
                {props.targetElement.title} is assigned to {props.targetElement.sharedInfo.length} {pluralize('user', props.targetElement.sharedInfo.length)}. Do you want to assign uploaded items to all assigned users?
            </Dialog.Title>
            <Separator />
            <Stack space='md' className='p-4 overflow-y-scroll scrollbar-stable'>
                {state.teams.map((team) => <Stack space='md' key={team.label}>
                    <Stack className='bg-black-10 p-2 border-b border-gray-neutral-80' space='sm' row>
                        <Badge variant='secondary' circle size='md'>
                            {team.options.length}
                        </Badge>
                        <Text size='sm' weight='medium'>
                            {team.label}
                        </Text>
                    </Stack>
                    <ul className='flex flex-col gap-4'>
                        {team.options.map((option) => <li className='flex flex-row gap-2' key={option.value}>
                            <Checkbox
                                size='sm'
                                checked={state.selectedIds.includes(option.value)}
                                onCheckedChange={() => state.onUserToggle(option.value)}
                            />
                            {option.avatar}
                            <UserNameText
                                size='sm'
                                user={option.user}
                                isMe={option.isMe}
                            />
                        </li>)}
                    </ul>
                </Stack>)}
            </Stack>
            <Dialog.Footer className='justify-between sm:justify-between'>
                <Dialog.Close asChild>
                    <Button className='min-w-20 hidden sm:inline-flex' variant='outline' >Cancel</Button>
                </Dialog.Close>
                <Stack space='sm' justify="end" className='flex-1' row>
                    <Button
                        onClick={() => props.onConfirm([])}
                        variant='outline'>Upload Only</Button>
                    <Button
                        onClick={state.onConfirmClick}
                        disabled={state.selectedIds.length === 0}>Upload and Assign</Button>
                </Stack>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>
}