import { Box } from '@mui/material';
import Accordion from 'components/dist/atoms/Accordion';
import Button from 'components/dist/atoms/Button';
import { Calendar } from 'components/dist/atoms/Calendar';
import Checkbox from 'components/dist/atoms/Checkbox';
import DropdownMenu from 'components/dist/atoms/DropdownMenu';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Label from 'components/dist/atoms/Label';
import Popover from 'components/dist/atoms/Popover';
import Select from 'components/dist/atoms/Select';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Textarea from 'components/dist/atoms/Textarea';
import FileIcon from 'components/dist/molecules/FileIcon';
import LoadingBox from 'components/dist/molecules/LoadingBox';
import { FormElementV2ResponseDto, PriorityType } from 'src/backend';
import { UsersSelectInput } from 'src/components/loans/users-select-input';
import { TemplateElementTree } from 'src/types/formelement';
import { Loan } from 'src/types/loan';
import { getFormElementPriorityColor, getFormElementPriorityLabel, getPriorityIcon } from 'src/utils/form-elements';
import { classNames } from 'src/utils/tailwind-class-names';

import { EditFormElementMultipleEntitiesConfirmDialog } from './edit-form-element-multiple-entities-confirm-dialog.component';
import { useFormElementFormState } from './form-element-form.state';

export interface FormElementFormProps {
    elementIds: string[];
    parentId: string;
    loanEntities: Loan['loanEntities'];
    loanRoles: Loan['loanRoles'];
    lenderId: Loan['lender']['id'];
    loanId: string;
    onSuccess?: () => void;
    onCancel: () => void;
}


export const EditFormElementDetails = (props: FormElementFormProps) => {
    const state = useFormElementFormState(props);

    if (state.isLoading) {
        return <LoadingBox
            title="Loading" hint="This will take a moment.." />;
    }

    return (<Stack
        asChild
        className='divide-y divide-gray-neutral-80 overflow-hidden w-[850px] max-w-full flex-1'>
        <form onSubmit={state.formik.handleSubmit}>
            <Stack className='py-3 px-4'>
                <Text size='sm' weight="medium">
                    {state.title}
                </Text>
            </Stack>
            <Stack
                className='divide-x flex-col sm:flex-row divide-gray-neutral-80 overflow-hidden flex-1'>
                <Stack
                    space="xl"
                    className='flex-1 p-3 sm:p-6 overflow-y-scroll scrollbar-stable'>
                    <div
                        className='grid gap-4 grid-cols-1 sm:grid-cols-2'>
                        {!state.isMultiEdit && <Stack
                            space="sm"
                        >
                            <Label variant='secondary' size='md' htmlFor='title'>Name</Label>
                            <Input
                                error={state.formik.touched.title && !!state.formik.errors.title}
                                value={state.formik.values.title}
                                onChange={state.formik.handleChange}
                                onBlur={state.formik.handleBlur}
                                id="title"
                                variant='xmd'
                                name="title"
                                wrapClassName='bg-white'
                                placeholder='Enter Title' />
                        </Stack>}
                        <Stack space="sm">
                            <Label
                                htmlFor='entityId'
                                truncate
                                size='md'
                                variant='secondary'
                                className='shrink-0'>
                                {state.isEdit
                                    ? `Entity`
                                    : "What entity is this file request about?"}
                            </Label>
                            <Select
                                disabled={!state.formik.values.isEditingEntityEnabled}
                                onValueChange={(value: string) => value && state.onEntitySelected(value)}
                                value={state.formik.values.entityId}
                            >
                                <Select.Trigger
                                    id="entityId"
                                    className='h-10'
                                    error={state.formik.touched.entityId && !!state.formik.errors.entityId}>
                                    <Select.Value
                                        aria-label="Select Entity"
                                        placeholder="Select Entity" />
                                </Select.Trigger>
                                <Select.Content>
                                    {state.entities.map((entity) => (
                                        <Select.Item
                                            key={entity.id}
                                            value={entity.id}>
                                            {entity.name}
                                        </Select.Item>
                                    ))}
                                </Select.Content>
                            </Select>
                        </Stack>
                        {state.isEditingMultipleEntities && <Stack space='sm' items='start'>
                            <Label>&nbsp;</Label>
                            <Button
                                disabled={state.formik.values.isEditingEntityEnabled}
                                onClick={() => state.formik.setFieldValue('isEditingEntityEnabled', true)}
                                variant='outline'
                                className={classNames('gap-1', {
                                    'text-blue-100': !state.formik.values.isEditingEntityEnabled
                                })}>
                                <Icon name="EditPencil" width={20} height={20} strokeWidth={2} />  Edit Entity
                            </Button>
                        </Stack>}
                    </div>
                    <div className='grid grid-cols-3 gap-2 sm:flex sm:flex-wrap sm:gap-2 sm:items-center'>
                        <Stack
                            row
                            space="sm"
                            className='flex item-center'>
                            <Checkbox
                                size='sm'
                                checked={state.formik.values.canFill}
                                onCheckedChange={(checked: boolean) => state.handleCheckChange('canFill', checked)}
                                id="canFill"
                                name="canFill"
                                className='bg-white' />
                            <Label
                                size="sm"
                                htmlFor='canFill'
                                className='items-center flex'>Can Fill</Label>
                        </Stack>
                        <Stack
                            row
                            space="sm"
                            className='flex item-center'>
                            <Checkbox
                                size='sm'
                                checked={state.formik.values.canExpire}
                                onCheckedChange={(checked: boolean) => state.handleCheckChange('canExpire', checked)}
                                id="canExpire"
                                name="canExpire"
                                className='bg-white' />
                            <Label
                                size="sm"
                                htmlFor='canExpire'
                                className='items-center flex'>Can Expire</Label>
                        </Stack>
                        <Stack
                            row
                            space="sm"
                            className='flex item-center'>
                            <Checkbox
                                size='sm'
                                checked={state.formik.values.notary}
                                onCheckedChange={(checked: boolean) => state.handleCheckChange('notary', checked)}
                                id="notary"
                                name="notary"
                                className='bg-white' />
                            <Label
                                size="sm"
                                htmlFor='notary'
                                className='items-center flex'>Notary</Label>
                        </Stack>
                        <Stack
                            row
                            space="sm"
                            className='flex item-center'>
                            <Checkbox
                                size='sm'
                                checked={state.formik.values.wetSign}
                                onCheckedChange={(checked: boolean) => state.handleCheckChange('wetSign', checked)}
                                id="wetSign"
                                name="wetSign"
                                className='bg-white' />
                            <Label
                                size="sm"
                                htmlFor='wetSign'
                                className='items-center flex'>Wet Signature</Label>
                        </Stack>
                        <Stack
                            row
                            space="sm"
                            className='flex item-center'>
                            <Checkbox
                                size='sm'
                                checked={state.formik.values.needsLegalReview}
                                onCheckedChange={(checked: boolean) => state.handleCheckChange('needsLegalReview', checked)}
                                id="needsLegalReview"
                                name="needsLegalReview"
                                className='bg-white' />
                            <Label
                                size="sm"
                                htmlFor='needsLegalReview'
                                className='items-center flex'>Legal Review</Label>
                        </Stack>
                        <Stack
                            row
                            space="sm"
                            className='flex item-center'>
                            <Checkbox
                                size='sm'
                                checked={state.formik.values.audit}
                                onCheckedChange={(checked: boolean) => state.handleCheckChange('audit', checked)}
                                id="audit"
                                name="audit"
                                className='bg-white' />
                            <Label
                                size="sm"
                                htmlFor='audit'
                                className='items-center flex'>Audit</Label>
                        </Stack>
                        <Stack
                            row
                            space="sm"
                            className='flex item-center'>
                            <Checkbox
                                size='sm'
                                checked={state.formik.values.submission}
                                onCheckedChange={(checked: boolean) => state.handleCheckChange('submission', checked)}
                                id="submission"
                                name="submission"
                                className='bg-white' />
                            <Label
                                size="sm"
                                htmlFor='submission'
                                className='items-center flex'>Submission</Label>
                        </Stack>
                    </div>
                    <Stack space="sm">
                        <Label htmlFor='assignTo' variant='secondary' size='md'>Assign To</Label>
                        <UsersSelectInput
                            onChange={state.onAssignedUserListChange}
                            lenderId={props.lenderId}
                            selectedList={state.formik.values.assignedList}
                            unassignedChecked={undefined}
                            loanRoles={props.loanRoles}
                            loanEntities={props.loanEntities}
                        />
                    </Stack>
                    <Stack space="sm">
                        <Label htmlFor='title' variant='secondary' size='md'>Description</Label>
                        <Textarea
                            error={state.formik.touched.description && !!state.formik.errors.description}
                            value={state.formik.values.description}
                            onChange={state.formik.handleChange}
                            onBlur={state.formik.handleBlur}
                            id="description"
                            maxLength={250}
                            name="description"
                            variant='xmd'
                            className="scrollbar-stable h-32"
                            wrapClassName='bg-white relative px-0 pb-5'
                            placeholder='Enter Description..' >
                            <Text
                                size="xs"
                                variant={state.descriptionLength >= 250 ? "destructive" : "secondary"}
                                className="absolute  bg-white p-1 rounded-sm bottom-0 right-0 mr-3 mb-1">
                                {state.descriptionLength}/250
                            </Text>
                        </Textarea>
                        <Stack
                            row
                            space="sm"
                            className='flex item-center'>
                            <Checkbox
                                size='sm'
                                checked={state.formik.values.includeWithMessage}
                                onCheckedChange={(checked: boolean) => state.handleCheckChange('includeWithMessage', checked)}
                                id="includeWithMessage"
                                name="includeWithMessage"
                                className='bg-white' />
                            <Label
                                size='sm'
                                htmlFor='includeWithMessage'
                                className='items-center flex'>Include with message</Label>
                        </Stack>
                    </Stack>
                    <Stack row space="lg">
                        <Stack space='sm'>
                            <Popover>
                                <Popover.Trigger asChild>
                                    <Button
                                        weight="normal"
                                        size='sm'
                                        variant="outline"
                                        className='bg-white gap-2 shrink-0 text-xs'>
                                        <Icon name="Calendar" width={18} height={18} />
                                        {!state.formik.values.dueDate ? 'Set due date' : 'Due date: ' + state.formattedDueDate}
                                    </Button>
                                </Popover.Trigger>
                                <Popover.Content
                                    align="start"
                                    className="flex w-auto flex-col space-y-2 p-2 z-[1202]"
                                >
                                    <Calendar
                                        mode="single"
                                        onSelect={state.onDueDateSelected} />
                                </Popover.Content>
                            </Popover>
                            {state.dueDateStatus === 'danger' && <Text
                                as="div"
                                size="xs"
                                className="flex gap-1 items-start"
                                variant="destructive">
                                <Icon name="InfoEmpty" width={12} height={12} strokeWidth={2} className="mt-0.5" />
                                Due
                            </Text>}
                            {state.dueDateStatus === 'warning' && <Text
                                as="div"
                                size="xs"
                                className="flex gap-1 items-start"
                                variant="warning">
                                <Icon name="InfoEmpty" width={12} height={12} strokeWidth={2} className="mt-0.5" />
                                Almost due
                            </Text>}
                        </Stack>
                        <DropdownMenu
                            modal={false}>
                            <DropdownMenu.Trigger asChild>
                                <Button
                                    weight="normal"
                                    size='sm'
                                    variant="outline"
                                    className='bg-white gap-2 shrink-0 text-xs'>
                                    {!state.formik.values.priority ? <>
                                        <Icon name="Menu" width={18} height={18} />
                                        Set priority</> : <Stack row items="center" space="sm">
                                        <Box
                                            className='[&>svg]:h-5 [&>svg]:aspect-square'
                                            component="span"
                                            sx={{
                                                '& svg': {
                                                    color: `${getFormElementPriorityColor(state.formik.values.priority)}.main`,
                                                }
                                            }}
                                        >
                                            {getPriorityIcon(state.formik.values.priority)}
                                        </Box>
                                        <Text capitalize as="span" size="xs">{getFormElementPriorityLabel(state.formik.values.priority)}</Text>
                                    </Stack>}
                                </Button>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                                {state.priorities.map((priority: PriorityType) => (
                                    <DropdownMenu.Item key={priority} className="h-10" onSelect={() => state.onPrioritySelected(priority)}>
                                        <Stack
                                            row
                                            space="sm"
                                            className="items-center cursor-pointer w-full"
                                        >
                                            <Box sx={{
                                                '& svg': {
                                                    color: `${getFormElementPriorityColor(priority)}.main`,
                                                }
                                            }}
                                            >
                                                {getPriorityIcon(priority)}
                                            </Box>
                                            <Text size="sm" capitalize>{getFormElementPriorityLabel(priority)}</Text>
                                        </Stack>
                                    </DropdownMenu.Item>
                                ))}
                            </DropdownMenu.Content>
                        </DropdownMenu>

                    </Stack>
                </Stack>
            </Stack >
            <Stack
                row
                className='px-6 py-2'
                space="sm"
                justify="end">
                <Button
                    onClick={() => props.onCancel()}
                    disabled={state.isSubmitting}
                    variant='outline'>Cancel</Button>
                <Button
                    type="submit"
                    loading={state.isSubmitting}
                    className='min-w-20'>Save</Button>
            </Stack>
            {
                state.formik.values.isConfirmationDialogOpen && <EditFormElementMultipleEntitiesConfirmDialog
                    loading={state.isSubmitting}
                    onCancel={state.onConfirmationDialogCancel}
                    onConfirm={state.onConfirmationDialogConfirm}
                    targetEntityName={state.entityToEditName}
                    elements={state.elementsToAssignNewEntity} />
            }
        </form >
    </Stack >)
};

interface TreeAccordionProps {
    folder: TemplateElementTree<FormElementV2ResponseDto>;
    onFolderSelected: (packageInfoId: string) => void;
    selectedFolderId: string;
}

const TreeAccordion = (props: TreeAccordionProps) => {
    if (props.folder.storageType === 'FILE') return <Stack
        id={`request-${props.folder.id}`}
        row
        className="py-3 snap-start scroll-mt-6 ml-5 overflow-hidden items-center"
        space="sm">
        <FileIcon
            size="small"
            className='shrink-0'
            fileName={props.folder.element.answer?.document?.name} />
        <Text
            truncate
            size="sm"
        >
            {props.folder.title}
        </Text>
    </Stack>
    return <Accordion.Item
        className='ml-5'
        value={props.folder.id}>
        <Accordion.Trigger
            className='[&[data-state=open]_.folder-closed]:hidden [&[data-state=closed]_.folder-open]:hidden cursor-pointer'
            asChild>
            <Stack
                row
                items="center"
                space="sm">
                <div
                    className='w-4 h-4'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                    <Checkbox
                        size='sm'
                        aria-label={`Select ${props.folder.title}`}
                        checked={props.selectedFolderId === props.folder.id}
                        onCheckedChange={() => props.onFolderSelected(props.folder.id)}
                        className='bg-white' />
                </div>
                <Icon
                    name="FolderOpen"
                    className='folder-open'
                    width={18}
                    height={18}
                    strokeWidth={2} />
                <Icon
                    name="ClientFolder"
                    className='folder-closed text-yellow-60'
                    width={18}
                    height={18}
                    strokeWidth={2} />
                <Text
                    size="sm">
                    {props.folder.title}
                </Text>
            </Stack>
        </Accordion.Trigger>
        <Accordion.Content className='border-l border-gray-neutral-40 ml-2'>
            {props.folder.children.map((subfolder) => (<TreeAccordion
                onFolderSelected={props.onFolderSelected}
                key={subfolder.id}
                selectedFolderId={props.selectedFolderId}
                folder={subfolder}
            />))}
        </Accordion.Content>
    </Accordion.Item>
}