import { j as A } from "./jsx-runtime-D0AQ1Mn7.js";
import { a as K, _ as C, $ as Tt } from "./index-BmyyIj4u.js";
import * as de from "react";
import { forwardRef as O, useContext as It, createContext as Ot, useState as I, useEffect as B, createElement as m, useRef as W, useCallback as U, Fragment as Ne, useMemo as Nt } from "react";
import Dt, { createPortal as Ge } from "react-dom";
import { $ as Fe } from "./index-CxNOZmcj.js";
import { d as L, b as $e, $ as qe, a as ee, c as He } from "./index-C8YCJLum.js";
import { $ as At } from "./index-BHhtJoCl.js";
import { $ as Rt } from "./index-U5S6G_il.js";
import { $ as M, a as Lt } from "./index-CaNEl95H.js";
import { $ as Mt } from "./index-D4lFLFGb.js";
import { $ as Ft, R as Ht } from "./Combination-C3243Ojg.js";
import { $ as De } from "./index-DkHhaJ_5.js";
import { u as kt, o as Bt, s as Wt, f as Vt, a as Ut, b as Kt, h as jt, l as zt, c as Yt } from "./floating-ui.react-dom-88_vs_AG.js";
import { $ as Io } from "./index-DyEECGD5.js";
import { $ as Xt } from "./index-BLz-1KLb.js";
import { $ as Gt } from "./index-CX8l_p9w.js";
import { h as qt } from "./index-pK5bN1zh.js";
import { c as Zt } from "./index-CYs0Zmg8.js";
import { c as le } from "./utils-CJ9afRe1.js";
import { I as Se } from "./Icon-CtH7buGH.js";
const Ie = "dismissableLayer.update", Jt = "dismissableLayer.pointerDownOutside", Qt = "dismissableLayer.focusOutside";
let ke;
const en = /* @__PURE__ */ Ot({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), tn = /* @__PURE__ */ O((t, n) => {
  var e;
  const { disableOutsidePointerEvents: o = !1, onEscapeKeyDown: a, onPointerDownOutside: i, onFocusOutside: c, onInteractOutside: r, onDismiss: s, ...l } = t, p = It(en), [h, E] = I(null), v = (e = h == null ? void 0 : h.ownerDocument) !== null && e !== void 0 ? e : globalThis == null ? void 0 : globalThis.document, [, g] = I({}), u = K(
    n,
    (w) => E(w)
  ), x = Array.from(p.layers), [f] = [
    ...p.layersWithOutsidePointerEventsDisabled
  ].slice(-1), d = x.indexOf(f), $ = h ? x.indexOf(h) : -1, P = p.layersWithOutsidePointerEventsDisabled.size > 0, D = $ >= d, te = nn((w) => {
    const H = w.target, q = [
      ...p.branches
    ].some(
      (j) => j.contains(H)
    );
    !D || q || (i == null || i(w), r == null || r(w), w.defaultPrevented || s == null || s());
  }, v), N = on((w) => {
    const H = w.target;
    [
      ...p.branches
    ].some(
      (j) => j.contains(H)
    ) || (c == null || c(w), r == null || r(w), w.defaultPrevented || s == null || s());
  }, v);
  return Mt((w) => {
    $ === p.layers.size - 1 && (a == null || a(w), !w.defaultPrevented && s && (w.preventDefault(), s()));
  }, v), B(() => {
    if (h)
      return o && (p.layersWithOutsidePointerEventsDisabled.size === 0 && (ke = v.body.style.pointerEvents, v.body.style.pointerEvents = "none"), p.layersWithOutsidePointerEventsDisabled.add(h)), p.layers.add(h), Be(), () => {
        o && p.layersWithOutsidePointerEventsDisabled.size === 1 && (v.body.style.pointerEvents = ke);
      };
  }, [
    h,
    v,
    o,
    p
  ]), B(() => () => {
    h && (p.layers.delete(h), p.layersWithOutsidePointerEventsDisabled.delete(h), Be());
  }, [
    h,
    p
  ]), B(() => {
    const w = () => g({});
    return document.addEventListener(Ie, w), () => document.removeEventListener(Ie, w);
  }, []), /* @__PURE__ */ m(M.div, C({}, l, {
    ref: u,
    style: {
      pointerEvents: P ? D ? "auto" : "none" : void 0,
      ...t.style
    },
    onFocusCapture: L(t.onFocusCapture, N.onFocusCapture),
    onBlurCapture: L(t.onBlurCapture, N.onBlurCapture),
    onPointerDownCapture: L(t.onPointerDownCapture, te.onPointerDownCapture)
  }));
});
function nn(t, n = globalThis == null ? void 0 : globalThis.document) {
  const e = $e(t), o = W(!1), a = W(() => {
  });
  return B(() => {
    const i = (r) => {
      if (r.target && !o.current) {
        let l = function() {
          Ze(Jt, e, s, {
            discrete: !0
          });
        };
        const s = {
          originalEvent: r
        };
        r.pointerType === "touch" ? (n.removeEventListener("click", a.current), a.current = l, n.addEventListener("click", a.current, {
          once: !0
        })) : l();
      } else
        n.removeEventListener("click", a.current);
      o.current = !1;
    }, c = window.setTimeout(() => {
      n.addEventListener("pointerdown", i);
    }, 0);
    return () => {
      window.clearTimeout(c), n.removeEventListener("pointerdown", i), n.removeEventListener("click", a.current);
    };
  }, [
    n,
    e
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => o.current = !0
  };
}
function on(t, n = globalThis == null ? void 0 : globalThis.document) {
  const e = $e(t), o = W(!1);
  return B(() => {
    const a = (i) => {
      i.target && !o.current && Ze(Qt, e, {
        originalEvent: i
      }, {
        discrete: !1
      });
    };
    return n.addEventListener("focusin", a), () => n.removeEventListener("focusin", a);
  }, [
    n,
    e
  ]), {
    onFocusCapture: () => o.current = !0,
    onBlurCapture: () => o.current = !1
  };
}
function Be() {
  const t = new CustomEvent(Ie);
  document.dispatchEvent(t);
}
function Ze(t, n, e, { discrete: o }) {
  const a = e.originalEvent.target, i = new CustomEvent(t, {
    bubbles: !1,
    cancelable: !0,
    detail: e
  });
  n && a.addEventListener(t, n, {
    once: !0
  }), o ? Lt(a, i) : a.dispatchEvent(i);
}
const _e = "focusScope.autoFocusOnMount", Te = "focusScope.autoFocusOnUnmount", We = {
  bubbles: !1,
  cancelable: !0
}, rn = /* @__PURE__ */ O((t, n) => {
  const { loop: e = !1, trapped: o = !1, onMountAutoFocus: a, onUnmountAutoFocus: i, ...c } = t, [r, s] = I(null), l = $e(a), p = $e(i), h = W(null), E = K(
    n,
    (u) => s(u)
  ), v = W({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  B(() => {
    if (o) {
      let u = function($) {
        if (v.paused || !r) return;
        const P = $.target;
        r.contains(P) ? h.current = P : ie(h.current, {
          select: !0
        });
      }, x = function($) {
        if (v.paused || !r) return;
        const P = $.relatedTarget;
        P !== null && (r.contains(P) || ie(h.current, {
          select: !0
        }));
      }, f = function($) {
        if (document.activeElement === document.body)
          for (const D of $) D.removedNodes.length > 0 && ie(r);
      };
      document.addEventListener("focusin", u), document.addEventListener("focusout", x);
      const d = new MutationObserver(f);
      return r && d.observe(r, {
        childList: !0,
        subtree: !0
      }), () => {
        document.removeEventListener("focusin", u), document.removeEventListener("focusout", x), d.disconnect();
      };
    }
  }, [
    o,
    r,
    v.paused
  ]), B(() => {
    if (r) {
      Ue.add(v);
      const u = document.activeElement;
      if (!r.contains(u)) {
        const f = new CustomEvent(_e, We);
        r.addEventListener(_e, l), r.dispatchEvent(f), f.defaultPrevented || (an(un(Je(r)), {
          select: !0
        }), document.activeElement === u && ie(r));
      }
      return () => {
        r.removeEventListener(_e, l), setTimeout(() => {
          const f = new CustomEvent(Te, We);
          r.addEventListener(Te, p), r.dispatchEvent(f), f.defaultPrevented || ie(u ?? document.body, {
            select: !0
          }), r.removeEventListener(Te, p), Ue.remove(v);
        }, 0);
      };
    }
  }, [
    r,
    l,
    p,
    v
  ]);
  const g = U((u) => {
    if (!e && !o || v.paused) return;
    const x = u.key === "Tab" && !u.altKey && !u.ctrlKey && !u.metaKey, f = document.activeElement;
    if (x && f) {
      const d = u.currentTarget, [$, P] = cn(d);
      $ && P ? !u.shiftKey && f === P ? (u.preventDefault(), e && ie($, {
        select: !0
      })) : u.shiftKey && f === $ && (u.preventDefault(), e && ie(P, {
        select: !0
      })) : f === d && u.preventDefault();
    }
  }, [
    e,
    o,
    v.paused
  ]);
  return /* @__PURE__ */ m(M.div, C({
    tabIndex: -1
  }, c, {
    ref: E,
    onKeyDown: g
  }));
});
function an(t, { select: n = !1 } = {}) {
  const e = document.activeElement;
  for (const o of t)
    if (ie(o, {
      select: n
    }), document.activeElement !== e) return;
}
function cn(t) {
  const n = Je(t), e = Ve(n, t), o = Ve(n.reverse(), t);
  return [
    e,
    o
  ];
}
function Je(t) {
  const n = [], e = document.createTreeWalker(t, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (o) => {
      const a = o.tagName === "INPUT" && o.type === "hidden";
      return o.disabled || o.hidden || a ? NodeFilter.FILTER_SKIP : o.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; e.nextNode(); ) n.push(e.currentNode);
  return n;
}
function Ve(t, n) {
  for (const e of t)
    if (!sn(e, {
      upTo: n
    })) return e;
}
function sn(t, { upTo: n }) {
  if (getComputedStyle(t).visibility === "hidden") return !0;
  for (; t; ) {
    if (n !== void 0 && t === n) return !1;
    if (getComputedStyle(t).display === "none") return !0;
    t = t.parentElement;
  }
  return !1;
}
function ln(t) {
  return t instanceof HTMLInputElement && "select" in t;
}
function ie(t, { select: n = !1 } = {}) {
  if (t && t.focus) {
    const e = document.activeElement;
    t.focus({
      preventScroll: !0
    }), t !== e && ln(t) && n && t.select();
  }
}
const Ue = dn();
function dn() {
  let t = [];
  return {
    add(n) {
      const e = t[0];
      n !== e && (e == null || e.pause()), t = Ke(t, n), t.unshift(n);
    },
    remove(n) {
      var e;
      t = Ke(t, n), (e = t[0]) === null || e === void 0 || e.resume();
    }
  };
}
function Ke(t, n) {
  const e = [
    ...t
  ], o = e.indexOf(n);
  return o !== -1 && e.splice(o, 1), e;
}
function un(t) {
  return t.filter(
    (n) => n.tagName !== "A"
  );
}
const Qe = "Popper", [et, tt] = qe(Qe), [fn, nt] = et(Qe), pn = (t) => {
  const { __scopePopper: n, children: e } = t, [o, a] = I(null);
  return /* @__PURE__ */ m(fn, {
    scope: n,
    anchor: o,
    onAnchorChange: a
  }, e);
}, mn = "PopperAnchor", $n = /* @__PURE__ */ O((t, n) => {
  const { __scopePopper: e, virtualRef: o, ...a } = t, i = nt(mn, e), c = W(null), r = K(n, c);
  return B(() => {
    i.onAnchorChange((o == null ? void 0 : o.current) || c.current);
  }), o ? null : /* @__PURE__ */ m(M.div, C({}, a, {
    ref: r
  }));
}), ot = "PopperContent", [hn, Mo] = et(ot), gn = /* @__PURE__ */ O((t, n) => {
  var e, o, a, i, c, r, s, l;
  const { __scopePopper: p, side: h = "bottom", sideOffset: E = 0, align: v = "center", alignOffset: g = 0, arrowPadding: u = 0, avoidCollisions: x = !0, collisionBoundary: f = [], collisionPadding: d = 0, sticky: $ = "partial", hideWhenDetached: P = !1, updatePositionStrategy: D = "optimized", onPlaced: te, ...N } = t, w = nt(ot, p), [H, q] = I(null), j = K(
    n,
    (re) => q(re)
  ), [V, Q] = I(null), k = Xt(V), F = (e = k == null ? void 0 : k.width) !== null && e !== void 0 ? e : 0, z = (o = k == null ? void 0 : k.height) !== null && o !== void 0 ? o : 0, Z = h + (v !== "center" ? "-" + v : ""), ce = typeof d == "number" ? d : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...d
  }, J = Array.isArray(f) ? f : [
    f
  ], ve = J.length > 0, ne = {
    padding: ce,
    boundary: J.filter(vn),
    // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
    altBoundary: ve
  }, { refs: be, floatingStyles: pe, placement: se, isPositioned: oe, middlewareData: G } = kt({
    // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
    strategy: "fixed",
    placement: Z,
    whileElementsMounted: (...re) => Yt(...re, {
      animationFrame: D === "always"
    }),
    elements: {
      reference: w.anchor
    },
    middleware: [
      Bt({
        mainAxis: E + z,
        alignmentAxis: g
      }),
      x && Wt({
        mainAxis: !0,
        crossAxis: !1,
        limiter: $ === "partial" ? zt() : void 0,
        ...ne
      }),
      x && Vt({
        ...ne
      }),
      Ut({
        ...ne,
        apply: ({ elements: re, rects: me, availableWidth: xe, availableHeight: Ct }) => {
          const { width: Et, height: _t } = me.reference, we = re.floating.style;
          we.setProperty("--radix-popper-available-width", `${xe}px`), we.setProperty("--radix-popper-available-height", `${Ct}px`), we.setProperty("--radix-popper-anchor-width", `${Et}px`), we.setProperty("--radix-popper-anchor-height", `${_t}px`);
        }
      }),
      V && Kt({
        element: V,
        padding: u
      }),
      bn({
        arrowWidth: F,
        arrowHeight: z
      }),
      P && jt({
        strategy: "referenceHidden",
        ...ne
      })
    ]
  }), [b, _] = rt(se), R = $e(te);
  ee(() => {
    oe && (R == null || R());
  }, [
    oe,
    R
  ]);
  const T = (a = G.arrow) === null || a === void 0 ? void 0 : a.x, y = (i = G.arrow) === null || i === void 0 ? void 0 : i.y, S = ((c = G.arrow) === null || c === void 0 ? void 0 : c.centerOffset) !== 0, [Y, X] = I();
  return ee(() => {
    H && X(window.getComputedStyle(H).zIndex);
  }, [
    H
  ]), /* @__PURE__ */ m("div", {
    ref: be.setFloating,
    "data-radix-popper-content-wrapper": "",
    style: {
      ...pe,
      transform: oe ? pe.transform : "translate(0, -200%)",
      // keep off the page when measuring
      minWidth: "max-content",
      zIndex: Y,
      "--radix-popper-transform-origin": [
        (r = G.transformOrigin) === null || r === void 0 ? void 0 : r.x,
        (s = G.transformOrigin) === null || s === void 0 ? void 0 : s.y
      ].join(" ")
    },
    dir: t.dir
  }, /* @__PURE__ */ m(hn, {
    scope: p,
    placedSide: b,
    onArrowChange: Q,
    arrowX: T,
    arrowY: y,
    shouldHideArrow: S
  }, /* @__PURE__ */ m(M.div, C({
    "data-side": b,
    "data-align": _
  }, N, {
    ref: j,
    style: {
      ...N.style,
      // if the PopperContent hasn't been placed yet (not all measurements done)
      // we prevent animations so that users's animation don't kick in too early referring wrong sides
      animation: oe ? void 0 : "none",
      // hide the content if using the hide middleware and should be hidden
      opacity: (l = G.hide) !== null && l !== void 0 && l.referenceHidden ? 0 : void 0
    }
  }))));
});
function vn(t) {
  return t !== null;
}
const bn = (t) => ({
  name: "transformOrigin",
  options: t,
  fn(n) {
    var e, o, a, i, c;
    const { placement: r, rects: s, middlewareData: l } = n, h = ((e = l.arrow) === null || e === void 0 ? void 0 : e.centerOffset) !== 0, E = h ? 0 : t.arrowWidth, v = h ? 0 : t.arrowHeight, [g, u] = rt(r), x = {
      start: "0%",
      center: "50%",
      end: "100%"
    }[u], f = ((o = (a = l.arrow) === null || a === void 0 ? void 0 : a.x) !== null && o !== void 0 ? o : 0) + E / 2, d = ((i = (c = l.arrow) === null || c === void 0 ? void 0 : c.y) !== null && i !== void 0 ? i : 0) + v / 2;
    let $ = "", P = "";
    return g === "bottom" ? ($ = h ? x : `${f}px`, P = `${-v}px`) : g === "top" ? ($ = h ? x : `${f}px`, P = `${s.floating.height + v}px`) : g === "right" ? ($ = `${-v}px`, P = h ? x : `${d}px`) : g === "left" && ($ = `${s.floating.width + v}px`, P = h ? x : `${d}px`), {
      data: {
        x: $,
        y: P
      }
    };
  }
});
function rt(t) {
  const [n, e = "center"] = t.split("-");
  return [
    n,
    e
  ];
}
const xn = pn, wn = $n, yn = gn, Sn = /* @__PURE__ */ O((t, n) => {
  var e;
  const { container: o = globalThis == null || (e = globalThis.document) === null || e === void 0 ? void 0 : e.body, ...a } = t;
  return o ? /* @__PURE__ */ Dt.createPortal(/* @__PURE__ */ m(M.div, C({}, a, {
    ref: n
  })), o) : null;
}), Pn = /* @__PURE__ */ O((t, n) => /* @__PURE__ */ m(M.span, C({}, t, {
  ref: n,
  style: {
    // See: https://github.com/twbs/bootstrap/blob/master/scss/mixins/_screen-reader.scss
    position: "absolute",
    border: 0,
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    wordWrap: "normal",
    ...t.style
  }
}))), Cn = [
  " ",
  "Enter",
  "ArrowUp",
  "ArrowDown"
], En = [
  " ",
  "Enter"
], Pe = "Select", [Ce, Ee, _n] = At(Pe), [ge, Fo] = qe(Pe, [
  _n,
  tt
]), Ae = tt(), [Tn, ue] = ge(Pe), [In, On] = ge(Pe), Nn = (t) => {
  const { __scopeSelect: n, children: e, open: o, defaultOpen: a, onOpenChange: i, value: c, defaultValue: r, onValueChange: s, dir: l, name: p, autoComplete: h, disabled: E, required: v } = t, g = Ae(n), [u, x] = I(null), [f, d] = I(null), [$, P] = I(!1), D = Rt(l), [te = !1, N] = He({
    prop: o,
    defaultProp: a,
    onChange: i
  }), [w, H] = He({
    prop: c,
    defaultProp: r,
    onChange: s
  }), q = W(null), j = u ? !!u.closest("form") : !0, [V, Q] = I(/* @__PURE__ */ new Set()), k = Array.from(V).map(
    (F) => F.props.value
  ).join(";");
  return /* @__PURE__ */ m(xn, g, /* @__PURE__ */ m(Tn, {
    required: v,
    scope: n,
    trigger: u,
    onTriggerChange: x,
    valueNode: f,
    onValueNodeChange: d,
    valueNodeHasChildren: $,
    onValueNodeHasChildrenChange: P,
    contentId: De(),
    value: w,
    onValueChange: H,
    open: te,
    onOpenChange: N,
    dir: D,
    triggerPointerDownPosRef: q,
    disabled: E
  }, /* @__PURE__ */ m(Ce.Provider, {
    scope: n
  }, /* @__PURE__ */ m(In, {
    scope: t.__scopeSelect,
    onNativeOptionAdd: U((F) => {
      Q(
        (z) => new Set(z).add(F)
      );
    }, []),
    onNativeOptionRemove: U((F) => {
      Q((z) => {
        const Z = new Set(z);
        return Z.delete(F), Z;
      });
    }, [])
  }, e)), j ? /* @__PURE__ */ m(lt, {
    key: k,
    "aria-hidden": !0,
    required: v,
    tabIndex: -1,
    name: p,
    autoComplete: h,
    value: w,
    onChange: (F) => H(F.target.value),
    disabled: E
  }, w === void 0 ? /* @__PURE__ */ m("option", {
    value: ""
  }) : null, Array.from(V)) : null));
}, Dn = "SelectTrigger", An = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, disabled: o = !1, ...a } = t, i = Ae(e), c = ue(Dn, e), r = c.disabled || o, s = K(n, c.onTriggerChange), l = Ee(e), [p, h, E] = dt((g) => {
    const u = l().filter(
      (d) => !d.disabled
    ), x = u.find(
      (d) => d.value === c.value
    ), f = ut(u, g, x);
    f !== void 0 && c.onValueChange(f.value);
  }), v = () => {
    r || (c.onOpenChange(!0), E());
  };
  return /* @__PURE__ */ m(wn, C({
    asChild: !0
  }, i), /* @__PURE__ */ m(M.button, C({
    type: "button",
    role: "combobox",
    "aria-controls": c.contentId,
    "aria-expanded": c.open,
    "aria-required": c.required,
    "aria-autocomplete": "none",
    dir: c.dir,
    "data-state": c.open ? "open" : "closed",
    disabled: r,
    "data-disabled": r ? "" : void 0,
    "data-placeholder": it(c.value) ? "" : void 0
  }, a, {
    ref: s,
    onClick: L(a.onClick, (g) => {
      g.currentTarget.focus();
    }),
    onPointerDown: L(a.onPointerDown, (g) => {
      const u = g.target;
      u.hasPointerCapture(g.pointerId) && u.releasePointerCapture(g.pointerId), g.button === 0 && g.ctrlKey === !1 && (v(), c.triggerPointerDownPosRef.current = {
        x: Math.round(g.pageX),
        y: Math.round(g.pageY)
      }, g.preventDefault());
    }),
    onKeyDown: L(a.onKeyDown, (g) => {
      const u = p.current !== "";
      !(g.ctrlKey || g.altKey || g.metaKey) && g.key.length === 1 && h(g.key), !(u && g.key === " ") && Cn.includes(g.key) && (v(), g.preventDefault());
    })
  })));
}), Rn = "SelectValue", Ln = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, className: o, style: a, children: i, placeholder: c = "", ...r } = t, s = ue(Rn, e), { onValueNodeHasChildrenChange: l } = s, p = i !== void 0, h = K(n, s.onValueNodeChange);
  return ee(() => {
    l(p);
  }, [
    l,
    p
  ]), /* @__PURE__ */ m(M.span, C({}, r, {
    ref: h,
    style: {
      pointerEvents: "none"
    }
  }), it(s.value) ? /* @__PURE__ */ m(Ne, null, c) : i);
}), Mn = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, children: o, ...a } = t;
  return /* @__PURE__ */ m(M.span, C({
    "aria-hidden": !0
  }, a, {
    ref: n
  }), o || "▼");
}), Fn = (t) => /* @__PURE__ */ m(Sn, C({
  asChild: !0
}, t)), he = "SelectContent", Hn = /* @__PURE__ */ O((t, n) => {
  const e = ue(he, t.__scopeSelect), [o, a] = I();
  if (ee(() => {
    a(new DocumentFragment());
  }, []), !e.open) {
    const i = o;
    return i ? /* @__PURE__ */ Ge(/* @__PURE__ */ m(at, {
      scope: t.__scopeSelect
    }, /* @__PURE__ */ m(Ce.Slot, {
      scope: t.__scopeSelect
    }, /* @__PURE__ */ m("div", null, t.children))), i) : null;
  }
  return /* @__PURE__ */ m(kn, C({}, t, {
    ref: n
  }));
}), ae = 10, [at, fe] = ge(he), kn = /* @__PURE__ */ O((t, n) => {
  const {
    __scopeSelect: e,
    position: o = "item-aligned",
    onCloseAutoFocus: a,
    onEscapeKeyDown: i,
    onPointerDownOutside: c,
    side: r,
    sideOffset: s,
    align: l,
    alignOffset: p,
    arrowPadding: h,
    collisionBoundary: E,
    collisionPadding: v,
    sticky: g,
    hideWhenDetached: u,
    avoidCollisions: x,
    //
    ...f
  } = t, d = ue(he, e), [$, P] = I(null), [D, te] = I(null), N = K(
    n,
    (b) => P(b)
  ), [w, H] = I(null), [q, j] = I(null), V = Ee(e), [Q, k] = I(!1), F = W(!1);
  B(() => {
    if ($) return qt($);
  }, [
    $
  ]), Ft();
  const z = U((b) => {
    const [_, ...R] = V().map(
      (S) => S.ref.current
    ), [T] = R.slice(-1), y = document.activeElement;
    for (const S of b)
      if (S === y || (S == null || S.scrollIntoView({
        block: "nearest"
      }), S === _ && D && (D.scrollTop = 0), S === T && D && (D.scrollTop = D.scrollHeight), S == null || S.focus(), document.activeElement !== y)) return;
  }, [
    V,
    D
  ]), Z = U(
    () => z([
      w,
      $
    ]),
    [
      z,
      w,
      $
    ]
  );
  B(() => {
    Q && Z();
  }, [
    Q,
    Z
  ]);
  const { onOpenChange: ce, triggerPointerDownPosRef: J } = d;
  B(() => {
    if ($) {
      let b = {
        x: 0,
        y: 0
      };
      const _ = (T) => {
        var y, S, Y, X;
        b = {
          x: Math.abs(Math.round(T.pageX) - ((y = (S = J.current) === null || S === void 0 ? void 0 : S.x) !== null && y !== void 0 ? y : 0)),
          y: Math.abs(Math.round(T.pageY) - ((Y = (X = J.current) === null || X === void 0 ? void 0 : X.y) !== null && Y !== void 0 ? Y : 0))
        };
      }, R = (T) => {
        b.x <= 10 && b.y <= 10 ? T.preventDefault() : $.contains(T.target) || ce(!1), document.removeEventListener("pointermove", _), J.current = null;
      };
      return J.current !== null && (document.addEventListener("pointermove", _), document.addEventListener("pointerup", R, {
        capture: !0,
        once: !0
      })), () => {
        document.removeEventListener("pointermove", _), document.removeEventListener("pointerup", R, {
          capture: !0
        });
      };
    }
  }, [
    $,
    ce,
    J
  ]), B(() => {
    const b = () => ce(!1);
    return window.addEventListener("blur", b), window.addEventListener("resize", b), () => {
      window.removeEventListener("blur", b), window.removeEventListener("resize", b);
    };
  }, [
    ce
  ]);
  const [ve, ne] = dt((b) => {
    const _ = V().filter(
      (y) => !y.disabled
    ), R = _.find(
      (y) => y.ref.current === document.activeElement
    ), T = ut(_, b, R);
    T && setTimeout(
      () => T.ref.current.focus()
    );
  }), be = U((b, _, R) => {
    const T = !F.current && !R;
    (d.value !== void 0 && d.value === _ || T) && (H(b), T && (F.current = !0));
  }, [
    d.value
  ]), pe = U(
    () => $ == null ? void 0 : $.focus(),
    [
      $
    ]
  ), se = U((b, _, R) => {
    const T = !F.current && !R;
    (d.value !== void 0 && d.value === _ || T) && j(b);
  }, [
    d.value
  ]), oe = o === "popper" ? je : Bn, G = oe === je ? {
    side: r,
    sideOffset: s,
    align: l,
    alignOffset: p,
    arrowPadding: h,
    collisionBoundary: E,
    collisionPadding: v,
    sticky: g,
    hideWhenDetached: u,
    avoidCollisions: x
  } : {};
  return /* @__PURE__ */ m(at, {
    scope: e,
    content: $,
    viewport: D,
    onViewportChange: te,
    itemRefCallback: be,
    selectedItem: w,
    onItemLeave: pe,
    itemTextRefCallback: se,
    focusSelectedItem: Z,
    selectedItemText: q,
    position: o,
    isPositioned: Q,
    searchRef: ve
  }, /* @__PURE__ */ m(Ht, {
    as: Tt,
    allowPinchZoom: !0
  }, /* @__PURE__ */ m(rn, {
    asChild: !0,
    trapped: d.open,
    onMountAutoFocus: (b) => {
      b.preventDefault();
    },
    onUnmountAutoFocus: L(a, (b) => {
      var _;
      (_ = d.trigger) === null || _ === void 0 || _.focus({
        preventScroll: !0
      }), b.preventDefault();
    })
  }, /* @__PURE__ */ m(tn, {
    asChild: !0,
    disableOutsidePointerEvents: !0,
    onEscapeKeyDown: i,
    onPointerDownOutside: c,
    onFocusOutside: (b) => b.preventDefault(),
    onDismiss: () => d.onOpenChange(!1)
  }, /* @__PURE__ */ m(oe, C({
    role: "listbox",
    id: d.contentId,
    "data-state": d.open ? "open" : "closed",
    dir: d.dir,
    onContextMenu: (b) => b.preventDefault()
  }, f, G, {
    onPlaced: () => k(!0),
    ref: N,
    style: {
      // flex layout so we can place the scroll buttons properly
      display: "flex",
      flexDirection: "column",
      // reset the outline by default as the content MAY get focused
      outline: "none",
      ...f.style
    },
    onKeyDown: L(f.onKeyDown, (b) => {
      const _ = b.ctrlKey || b.altKey || b.metaKey;
      if (b.key === "Tab" && b.preventDefault(), !_ && b.key.length === 1 && ne(b.key), [
        "ArrowUp",
        "ArrowDown",
        "Home",
        "End"
      ].includes(b.key)) {
        let T = V().filter(
          (y) => !y.disabled
        ).map(
          (y) => y.ref.current
        );
        if ([
          "ArrowUp",
          "End"
        ].includes(b.key) && (T = T.slice().reverse()), [
          "ArrowUp",
          "ArrowDown"
        ].includes(b.key)) {
          const y = b.target, S = T.indexOf(y);
          T = T.slice(S + 1);
        }
        setTimeout(
          () => z(T)
        ), b.preventDefault();
      }
    })
  }))))));
}), Bn = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, onPlaced: o, ...a } = t, i = ue(he, e), c = fe(he, e), [r, s] = I(null), [l, p] = I(null), h = K(
    n,
    (N) => p(N)
  ), E = Ee(e), v = W(!1), g = W(!0), { viewport: u, selectedItem: x, selectedItemText: f, focusSelectedItem: d } = c, $ = U(() => {
    if (i.trigger && i.valueNode && r && l && u && x && f) {
      const N = i.trigger.getBoundingClientRect(), w = l.getBoundingClientRect(), H = i.valueNode.getBoundingClientRect(), q = f.getBoundingClientRect();
      if (i.dir !== "rtl") {
        const y = q.left - w.left, S = H.left - y, Y = N.left - S, X = N.width + Y, re = Math.max(X, w.width), me = window.innerWidth - ae, xe = Fe(S, [
          ae,
          me - re
        ]);
        r.style.minWidth = X + "px", r.style.left = xe + "px";
      } else {
        const y = w.right - q.right, S = window.innerWidth - H.right - y, Y = window.innerWidth - N.right - S, X = N.width + Y, re = Math.max(X, w.width), me = window.innerWidth - ae, xe = Fe(S, [
          ae,
          me - re
        ]);
        r.style.minWidth = X + "px", r.style.right = xe + "px";
      }
      const j = E(), V = window.innerHeight - ae * 2, Q = u.scrollHeight, k = window.getComputedStyle(l), F = parseInt(k.borderTopWidth, 10), z = parseInt(k.paddingTop, 10), Z = parseInt(k.borderBottomWidth, 10), ce = parseInt(k.paddingBottom, 10), J = F + z + Q + ce + Z, ve = Math.min(x.offsetHeight * 5, J), ne = window.getComputedStyle(u), be = parseInt(ne.paddingTop, 10), pe = parseInt(ne.paddingBottom, 10), se = N.top + N.height / 2 - ae, oe = V - se, G = x.offsetHeight / 2, b = x.offsetTop + G, _ = F + z + b, R = J - _;
      if (_ <= se) {
        const y = x === j[j.length - 1].ref.current;
        r.style.bottom = "0px";
        const S = l.clientHeight - u.offsetTop - u.offsetHeight, Y = Math.max(oe, G + (y ? pe : 0) + S + Z), X = _ + Y;
        r.style.height = X + "px";
      } else {
        const y = x === j[0].ref.current;
        r.style.top = "0px";
        const Y = Math.max(se, F + u.offsetTop + (y ? be : 0) + G) + R;
        r.style.height = Y + "px", u.scrollTop = _ - se + u.offsetTop;
      }
      r.style.margin = `${ae}px 0`, r.style.minHeight = ve + "px", r.style.maxHeight = V + "px", o == null || o(), requestAnimationFrame(
        () => v.current = !0
      );
    }
  }, [
    E,
    i.trigger,
    i.valueNode,
    r,
    l,
    u,
    x,
    f,
    i.dir,
    o
  ]);
  ee(
    () => $(),
    [
      $
    ]
  );
  const [P, D] = I();
  ee(() => {
    l && D(window.getComputedStyle(l).zIndex);
  }, [
    l
  ]);
  const te = U((N) => {
    N && g.current === !0 && ($(), d == null || d(), g.current = !1);
  }, [
    $,
    d
  ]);
  return /* @__PURE__ */ m(Wn, {
    scope: e,
    contentWrapper: r,
    shouldExpandOnScrollRef: v,
    onScrollButtonChange: te
  }, /* @__PURE__ */ m("div", {
    ref: s,
    style: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      zIndex: P
    }
  }, /* @__PURE__ */ m(M.div, C({}, a, {
    ref: h,
    style: {
      // When we get the height of the content, it includes borders. If we were to set
      // the height without having `boxSizing: 'border-box'` it would be too big.
      boxSizing: "border-box",
      // We need to ensure the content doesn't get taller than the wrapper
      maxHeight: "100%",
      ...a.style
    }
  }))));
}), je = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, align: o = "start", collisionPadding: a = ae, ...i } = t, c = Ae(e);
  return /* @__PURE__ */ m(yn, C({}, c, i, {
    ref: n,
    align: o,
    collisionPadding: a,
    style: {
      // Ensure border-box for floating-ui calculations
      boxSizing: "border-box",
      ...i.style,
      "--radix-select-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-select-content-available-width": "var(--radix-popper-available-width)",
      "--radix-select-content-available-height": "var(--radix-popper-available-height)",
      "--radix-select-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-select-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }));
}), [Wn, Re] = ge(he, {}), ze = "SelectViewport", Vn = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, ...o } = t, a = fe(ze, e), i = Re(ze, e), c = K(n, a.onViewportChange), r = W(0);
  return /* @__PURE__ */ m(Ne, null, /* @__PURE__ */ m("style", {
    dangerouslySetInnerHTML: {
      __html: "[data-radix-select-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch;}[data-radix-select-viewport]::-webkit-scrollbar{display:none}"
    }
  }), /* @__PURE__ */ m(Ce.Slot, {
    scope: e
  }, /* @__PURE__ */ m(M.div, C({
    "data-radix-select-viewport": "",
    role: "presentation"
  }, o, {
    ref: c,
    style: {
      // we use position: 'relative' here on the `viewport` so that when we call
      // `selectedItem.offsetTop` in calculations, the offset is relative to the viewport
      // (independent of the scrollUpButton).
      position: "relative",
      flex: 1,
      overflow: "auto",
      ...o.style
    },
    onScroll: L(o.onScroll, (s) => {
      const l = s.currentTarget, { contentWrapper: p, shouldExpandOnScrollRef: h } = i;
      if (h != null && h.current && p) {
        const E = Math.abs(r.current - l.scrollTop);
        if (E > 0) {
          const v = window.innerHeight - ae * 2, g = parseFloat(p.style.minHeight), u = parseFloat(p.style.height), x = Math.max(g, u);
          if (x < v) {
            const f = x + E, d = Math.min(v, f), $ = f - d;
            p.style.height = d + "px", p.style.bottom === "0px" && (l.scrollTop = $ > 0 ? $ : 0, p.style.justifyContent = "flex-end");
          }
        }
      }
      r.current = l.scrollTop;
    })
  }))));
}), Un = "SelectGroup", [Kn, jn] = ge(Un), zn = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, ...o } = t, a = De();
  return /* @__PURE__ */ m(Kn, {
    scope: e,
    id: a
  }, /* @__PURE__ */ m(M.div, C({
    role: "group",
    "aria-labelledby": a
  }, o, {
    ref: n
  })));
}), Yn = "SelectLabel", Xn = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, ...o } = t, a = jn(Yn, e);
  return /* @__PURE__ */ m(M.div, C({
    id: a.id
  }, o, {
    ref: n
  }));
}), Oe = "SelectItem", [Gn, ct] = ge(Oe), qn = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, value: o, disabled: a = !1, textValue: i, ...c } = t, r = ue(Oe, e), s = fe(Oe, e), l = r.value === o, [p, h] = I(i ?? ""), [E, v] = I(!1), g = K(n, (f) => {
    var d;
    return (d = s.itemRefCallback) === null || d === void 0 ? void 0 : d.call(s, f, o, a);
  }), u = De(), x = () => {
    a || (r.onValueChange(o), r.onOpenChange(!1));
  };
  if (o === "") throw new Error("A <Select.Item /> must have a value prop that is not an empty string. This is because the Select value can be set to an empty string to clear the selection and show the placeholder.");
  return /* @__PURE__ */ m(Gn, {
    scope: e,
    value: o,
    disabled: a,
    textId: u,
    isSelected: l,
    onItemTextChange: U((f) => {
      h((d) => {
        var $;
        return d || (($ = f == null ? void 0 : f.textContent) !== null && $ !== void 0 ? $ : "").trim();
      });
    }, [])
  }, /* @__PURE__ */ m(Ce.ItemSlot, {
    scope: e,
    value: o,
    disabled: a,
    textValue: p
  }, /* @__PURE__ */ m(M.div, C({
    role: "option",
    "aria-labelledby": u,
    "data-highlighted": E ? "" : void 0,
    "aria-selected": l && E,
    "data-state": l ? "checked" : "unchecked",
    "aria-disabled": a || void 0,
    "data-disabled": a ? "" : void 0,
    tabIndex: a ? void 0 : -1
  }, c, {
    ref: g,
    onFocus: L(
      c.onFocus,
      () => v(!0)
    ),
    onBlur: L(
      c.onBlur,
      () => v(!1)
    ),
    onPointerUp: L(c.onPointerUp, x),
    onPointerMove: L(c.onPointerMove, (f) => {
      if (a) {
        var d;
        (d = s.onItemLeave) === null || d === void 0 || d.call(s);
      } else
        f.currentTarget.focus({
          preventScroll: !0
        });
    }),
    onPointerLeave: L(c.onPointerLeave, (f) => {
      if (f.currentTarget === document.activeElement) {
        var d;
        (d = s.onItemLeave) === null || d === void 0 || d.call(s);
      }
    }),
    onKeyDown: L(c.onKeyDown, (f) => {
      var d;
      ((d = s.searchRef) === null || d === void 0 ? void 0 : d.current) !== "" && f.key === " " || (En.includes(f.key) && x(), f.key === " " && f.preventDefault());
    })
  }))));
}), ye = "SelectItemText", Zn = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, className: o, style: a, ...i } = t, c = ue(ye, e), r = fe(ye, e), s = ct(ye, e), l = On(ye, e), [p, h] = I(null), E = K(
    n,
    (f) => h(f),
    s.onItemTextChange,
    (f) => {
      var d;
      return (d = r.itemTextRefCallback) === null || d === void 0 ? void 0 : d.call(r, f, s.value, s.disabled);
    }
  ), v = p == null ? void 0 : p.textContent, g = Nt(
    () => /* @__PURE__ */ m("option", {
      key: s.value,
      value: s.value,
      disabled: s.disabled
    }, v),
    [
      s.disabled,
      s.value,
      v
    ]
  ), { onNativeOptionAdd: u, onNativeOptionRemove: x } = l;
  return ee(() => (u(g), () => x(g)), [
    u,
    x,
    g
  ]), /* @__PURE__ */ m(Ne, null, /* @__PURE__ */ m(M.span, C({
    id: s.textId
  }, i, {
    ref: E
  })), s.isSelected && c.valueNode && !c.valueNodeHasChildren ? /* @__PURE__ */ Ge(i.children, c.valueNode) : null);
}), Jn = "SelectItemIndicator", Qn = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, ...o } = t;
  return ct(Jn, e).isSelected ? /* @__PURE__ */ m(M.span, C({
    "aria-hidden": !0
  }, o, {
    ref: n
  })) : null;
}), Ye = "SelectScrollUpButton", eo = /* @__PURE__ */ O((t, n) => {
  const e = fe(Ye, t.__scopeSelect), o = Re(Ye, t.__scopeSelect), [a, i] = I(!1), c = K(n, o.onScrollButtonChange);
  return ee(() => {
    if (e.viewport && e.isPositioned) {
      let s = function() {
        const l = r.scrollTop > 0;
        i(l);
      };
      const r = e.viewport;
      return s(), r.addEventListener("scroll", s), () => r.removeEventListener("scroll", s);
    }
  }, [
    e.viewport,
    e.isPositioned
  ]), a ? /* @__PURE__ */ m(st, C({}, t, {
    ref: c,
    onAutoScroll: () => {
      const { viewport: r, selectedItem: s } = e;
      r && s && (r.scrollTop = r.scrollTop - s.offsetHeight);
    }
  })) : null;
}), Xe = "SelectScrollDownButton", to = /* @__PURE__ */ O((t, n) => {
  const e = fe(Xe, t.__scopeSelect), o = Re(Xe, t.__scopeSelect), [a, i] = I(!1), c = K(n, o.onScrollButtonChange);
  return ee(() => {
    if (e.viewport && e.isPositioned) {
      let s = function() {
        const l = r.scrollHeight - r.clientHeight, p = Math.ceil(r.scrollTop) < l;
        i(p);
      };
      const r = e.viewport;
      return s(), r.addEventListener("scroll", s), () => r.removeEventListener("scroll", s);
    }
  }, [
    e.viewport,
    e.isPositioned
  ]), a ? /* @__PURE__ */ m(st, C({}, t, {
    ref: c,
    onAutoScroll: () => {
      const { viewport: r, selectedItem: s } = e;
      r && s && (r.scrollTop = r.scrollTop + s.offsetHeight);
    }
  })) : null;
}), st = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, onAutoScroll: o, ...a } = t, i = fe("SelectScrollButton", e), c = W(null), r = Ee(e), s = U(() => {
    c.current !== null && (window.clearInterval(c.current), c.current = null);
  }, []);
  return B(() => () => s(), [
    s
  ]), ee(() => {
    var l;
    const p = r().find(
      (h) => h.ref.current === document.activeElement
    );
    p == null || (l = p.ref.current) === null || l === void 0 || l.scrollIntoView({
      block: "nearest"
    });
  }, [
    r
  ]), /* @__PURE__ */ m(M.div, C({
    "aria-hidden": !0
  }, a, {
    ref: n,
    style: {
      flexShrink: 0,
      ...a.style
    },
    onPointerDown: L(a.onPointerDown, () => {
      c.current === null && (c.current = window.setInterval(o, 50));
    }),
    onPointerMove: L(a.onPointerMove, () => {
      var l;
      (l = i.onItemLeave) === null || l === void 0 || l.call(i), c.current === null && (c.current = window.setInterval(o, 50));
    }),
    onPointerLeave: L(a.onPointerLeave, () => {
      s();
    })
  }));
}), no = /* @__PURE__ */ O((t, n) => {
  const { __scopeSelect: e, ...o } = t;
  return /* @__PURE__ */ m(M.div, C({
    "aria-hidden": !0
  }, o, {
    ref: n
  }));
});
function it(t) {
  return t === "" || t === void 0;
}
const lt = /* @__PURE__ */ O((t, n) => {
  const { value: e, ...o } = t, a = W(null), i = K(n, a), c = Gt(e);
  return B(() => {
    const r = a.current, s = window.HTMLSelectElement.prototype, p = Object.getOwnPropertyDescriptor(s, "value").set;
    if (c !== e && p) {
      const h = new Event("change", {
        bubbles: !0
      });
      p.call(r, e), r.dispatchEvent(h);
    }
  }, [
    c,
    e
  ]), /* @__PURE__ */ m(Pn, {
    asChild: !0
  }, /* @__PURE__ */ m("select", C({}, o, {
    ref: i,
    defaultValue: e
  })));
});
lt.displayName = "BubbleSelect";
function dt(t) {
  const n = $e(t), e = W(""), o = W(0), a = U((c) => {
    const r = e.current + c;
    n(r), function s(l) {
      e.current = l, window.clearTimeout(o.current), l !== "" && (o.current = window.setTimeout(
        () => s(""),
        1e3
      ));
    }(r);
  }, [
    n
  ]), i = U(() => {
    e.current = "", window.clearTimeout(o.current);
  }, []);
  return B(() => () => window.clearTimeout(o.current), []), [
    e,
    a,
    i
  ];
}
function ut(t, n, e) {
  const a = n.length > 1 && Array.from(n).every(
    (l) => l === n[0]
  ) ? n[0] : n, i = e ? t.indexOf(e) : -1;
  let c = oo(t, Math.max(i, 0));
  a.length === 1 && (c = c.filter(
    (l) => l !== e
  ));
  const s = c.find(
    (l) => l.textValue.toLowerCase().startsWith(a.toLowerCase())
  );
  return s !== e ? s : void 0;
}
function oo(t, n) {
  return t.map(
    (e, o) => t[(n + o) % t.length]
  );
}
const ro = Nn, ft = An, ao = Ln, co = Mn, so = Fn, pt = Hn, io = Vn, lo = zn, mt = Xn, $t = qn, ht = Zn, uo = Qn, gt = eo, vt = to, bt = no, fo = lo, po = ao, mo = Zt("flex h-10 w-full items-center justify-between whitespace-nowrap rounded-md border border-input bg-transparent px-3 py-2 text-sm  ring-offset-background data-[placeholder]:text-gray-neutral-100 focus:outline-none  disabled:cursor-not-allowed disabled:border-gray-neutral-80 disabled:bg-gray-neutral-10 [&>span]:line-clamp-1", {
  variants: {
    error: {
      true: "border-destructive",
      false: ""
    }
  },
  defaultVariants: {
    error: !1
  }
}), xt = de.forwardRef(({ className: t, children: n, error: e, ...o }, a) => /* @__PURE__ */ A.jsxs(
  ft,
  {
    ref: a,
    className: le(
      mo({ error: e }),
      t
    ),
    ...o,
    children: [
      n,
      /* @__PURE__ */ A.jsx(co, { asChild: !0, children: /* @__PURE__ */ A.jsx(Se, { name: "NavArrowDown", className: "text-black-primary", strokeWidth: 2, width: 18, height: 18 }) })
    ]
  }
));
xt.displayName = ft.displayName;
const Le = de.forwardRef(({ className: t, ...n }, e) => /* @__PURE__ */ A.jsx(
  gt,
  {
    ref: e,
    className: le(
      "flex cursor-default items-center justify-center py-1",
      t
    ),
    ...n,
    children: /* @__PURE__ */ A.jsx(Se, { name: "NavArrowUp", width: 16, height: 16 })
  }
));
Le.displayName = gt.displayName;
const Me = de.forwardRef(({ className: t, ...n }, e) => /* @__PURE__ */ A.jsx(
  vt,
  {
    ref: e,
    className: le(
      "flex cursor-default items-center justify-center py-1",
      t
    ),
    ...n,
    children: /* @__PURE__ */ A.jsx(Se, { name: "NavArrowDown", width: 16, height: 16 })
  }
));
Me.displayName = vt.displayName;
const wt = de.forwardRef(({ className: t, children: n, position: e = "popper", ...o }, a) => /* @__PURE__ */ A.jsx(so, { children: /* @__PURE__ */ A.jsxs(
  pt,
  {
    ref: a,
    className: le(
      "relative z-full-screen max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      e === "popper" && "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
      t
    ),
    position: e,
    ...o,
    children: [
      /* @__PURE__ */ A.jsx(Le, {}),
      /* @__PURE__ */ A.jsx(
        io,
        {
          className: le(
            "p-1",
            e === "popper" && "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
          ),
          children: n
        }
      ),
      /* @__PURE__ */ A.jsx(Me, {})
    ]
  }
) }));
wt.displayName = pt.displayName;
const yt = de.forwardRef(({ className: t, ...n }, e) => /* @__PURE__ */ A.jsx(
  mt,
  {
    ref: e,
    className: le("px-2 py-1.5 text-sm font-semibold", t),
    ...n
  }
));
yt.displayName = mt.displayName;
const St = de.forwardRef(({ className: t, children: n, ...e }, o) => /* @__PURE__ */ A.jsxs(
  $t,
  {
    ref: o,
    className: le(
      "relative flex w-full hover:bg-gray-50 cursor-pointer select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      t
    ),
    ...e,
    children: [
      /* @__PURE__ */ A.jsx("span", { className: "absolute right-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ A.jsx(uo, { children: /* @__PURE__ */ A.jsx(Se, { name: "Check", width: 16, height: 16 }) }) }),
      !e.noItemText && /* @__PURE__ */ A.jsx(ht, { children: n }),
      e.noItemText && n
    ]
  }
));
St.displayName = $t.displayName;
const Pt = de.forwardRef(({ className: t, ...n }, e) => /* @__PURE__ */ A.jsx(
  bt,
  {
    ref: e,
    className: le("-mx-1 my-1 h-px bg-muted", t),
    ...n
  }
));
Pt.displayName = bt.displayName;
const Ho = Object.assign(ro, {
  Content: wt,
  Group: fo,
  Item: St,
  Label: yt,
  ScrollDownButton: Me,
  ScrollUpButton: Le,
  Separator: Pt,
  Trigger: xt,
  Value: po,
  ItemText: ht
});
export {
  Ho as S
};
