import Icon, { IconProps } from "components/dist/atoms/Icon";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import { ElementModifierType } from "src/backend";

interface FormElementModifierLabelsProps {
    modifiers: ElementModifierType[];
}

const typeIconMap: Record<'SUBMISSION' | 'AUDITABLE' | 'NEEDS_LEGAL_REVIEW', IconProps['name']> = {
    'SUBMISSION': 'Submission',
    'AUDITABLE': 'Audit',
    'NEEDS_LEGAL_REVIEW': 'LegalHammer',
};
const typeLabelMap: Record<'SUBMISSION' | 'AUDITABLE' | 'NEEDS_LEGAL_REVIEW', string> = {
    'SUBMISSION': 'Submission',
    'AUDITABLE': 'Audit',
    'NEEDS_LEGAL_REVIEW': 'Legal Review',
};

//function to sort by order of AUDITABLE, NEEDS_LEGAL_REVIEW, SUBMISSION
const sortByOrder = (a: ElementModifierType, b: ElementModifierType) => {
    const order = ['AUDITABLE', 'NEEDS_LEGAL_REVIEW', 'SUBMISSION'];
    return order.indexOf(a) - order.indexOf(b);
}


export const FormElementModifierLabels = (props: FormElementModifierLabelsProps) => {
    // only show SUBMISSION, AUDITABLE, NEEDS_LEGAL_REVIEW
    const filteredModifiers = props.modifiers
        .filter(modifier => ['SUBMISSION', 'AUDITABLE', 'NEEDS_LEGAL_REVIEW'].includes(modifier));

    if (filteredModifiers.length === 0) {
        return null;
    }

    const sortedModifiers = filteredModifiers.toSorted(sortByOrder);

    return (
        <Stack row space="sm" items="center" className='pt-2 text-gray-neutral-70'>
            {sortedModifiers.map((modifier) => (<Stack row space='xs' key={modifier}>
                <Icon name={typeIconMap[modifier]} />
                <Text className='shrink-0' size='xs' variant='secondary'>{typeLabelMap[modifier]}</Text>
            </Stack>))}
        </Stack>
    );
};