import Button from "components/dist/atoms/Button"
import Command from "components/dist/atoms/Command"
import Dialog from "components/dist/atoms/Dialog"
import Icon from "components/dist/atoms/Icon"
import Input from "components/dist/atoms/Input";
import Label from "components/dist/atoms/Label"
import Popover from "components/dist/atoms/Popover"
import Select from "components/dist/atoms/Select";
import Separator from "components/dist/atoms/Separator";
import Stack from "components/dist/atoms/Stack"
import Text from "components/dist/atoms/Text"
import Tooltip from "components/dist/atoms/Tooltip"
import { SbaLoanGuard } from "src/components/loans/sba-loan-guard";
import { pluralize } from "src/utils/pluralize";

import { useCopyMoveToSelectEntity } from "./copy-move-to-select-entity-dialog.state";
import { ICopyMoveToSelectEntityDialogProps } from "./copy-move-to-select-entity-dialog.types";

const entityTypes = [
    { value: 'INDIVIDUAL', icon: 'User', label: 'Individual' },
    { value: 'COMPANY', icon: 'Building', label: 'Company' },
    { value: 'TRUST', icon: 'HomeSale', label: 'Trust' }
] as const;


export const CopyMoveToSelectEntityDialog = (props: ICopyMoveToSelectEntityDialogProps) => {
    const state = useCopyMoveToSelectEntity(props);

    return (<Dialog
        onOpenChange={isOpen => !isOpen && props.onCancel()}
        open
    >
        <Dialog.Content
            onOpenAutoFocus={event => event.preventDefault()}
            overlayClassName='z-full-screen'
            className="rounded max-w-[95%] sm:h-auto sm:max-w-2xl gap-0 sm:gap-0 z-full-screen">
            <form onSubmit={state.handleSubmit(state.onSubmit)}>
                <Dialog.Title className="pt-3 pb-2">
                    You’re copying {props.elements.length} {pluralize('item', props.elements.length)} to {state.loan?.projectName}. Do you want to add them to an entity?
                </Dialog.Title>
                <Separator />
                <Stack
                    space="xs"
                    className="p-4 divide-y divide-gray-neutral-80 h-80 max-h-full overflow-y-auto scrollbar-stable" >
                    {!state.isAddingNew && <Stack space='sm'>
                        <Label variant='secondary' htmlFor="entityId" size='sm'>
                            Entity
                        </Label>
                        <Popover
                            open={state.isPopoverOpen}
                            onOpenChange={state.setIsPopoverOpen}
                        >
                            <Popover.Trigger asChild>
                                <Button
                                    variant='outline'
                                    block
                                    className='text-sm hover:bg-white w-full sm:w-1/2 justify-start h-10 font-normal'>
                                    {state.selectedEntity ? state.selectedEntity.title : 'Select Entity'}
                                    <Icon name="NavArrowDown" width={20} height={20} className='ml-auto' strokeWidth={1.5} />
                                </Button>
                            </Popover.Trigger>
                            <Popover.Content
                                onOpenAutoFocus={(event) => event.preventDefault()}
                                className='p-2 w-96 max-w-full sm:w-80 sm:w-80 border-gray-neutral-80'>
                                <Command
                                    shouldFilter={false}>
                                    <Command.Input
                                        autoFocus={false}
                                        asChild>
                                        <Input
                                            value={state.inputValue}
                                            onChange={(e) => state.setInputValue(e.target.value)}
                                            variant='sm'
                                            wrapClassName='w-full focus-within:border-blue-100'
                                            className='placeholder:text-gray-neutral-70'
                                            placeholder='Create or Search Entity' >
                                            <Input.Prefix>
                                                <Icon name='Search' width={18} height={18} className='text-gray-neutral-70' strokeWidth={2} />
                                            </Input.Prefix>
                                            <Input.Suffix>
                                                <Button
                                                    variant="ghost" size='sm'
                                                    className="px-1 py-0 hover:bg-transparent">
                                                    <Icon name='Cancel' width={18} height={18} className='text-black-primary' strokeWidth={1.5} />
                                                </Button>
                                            </Input.Suffix>
                                        </Input>
                                    </Command.Input>
                                    <Command.List className='mt-4 h-48 overflow-auto scrollbar-stable'>
                                        {!!state.inputValue?.trim() && <Command.Empty className='p-0 gap-3 flex flex-col'>
                                            <Text size='xs' variant='secondary'>Entity does not exist</Text>
                                            <Button
                                                onClick={state.onAddNewClick}
                                                variant='secondary'
                                                className='text-blue-100 font-normal justify-start'
                                                block>
                                                Add &ldquo;{state.inputValue}&rdquo;
                                            </Button>
                                        </Command.Empty>
                                        }
                                        {state.filteredEntities.map(entity => <Command.Item
                                            key={entity.id}
                                            value={entity.id}
                                            keywords={[entity.title]}
                                            onSelect={() => state.setSherpaEntityId(entity.id)}
                                        >
                                            <Text size='sm' truncate>
                                                {entity.title}
                                            </Text>
                                        </Command.Item>)}
                                    </Command.List>
                                </Command>
                            </Popover.Content>
                        </Popover>
                    </Stack>}
                    {state.isAddingNew && <div className='gap-6 grid grid-cols-1 sm:grid-cols-2'>
                        <Stack space='sm'>
                            <Label variant='secondary' htmlFor="sherpaEntityType" size='sm'>
                                Type
                            </Label>
                            <Select
                                onValueChange={value => state.onFieldChange('sherpaEntityType', value)}
                                value={state.watchSherpaEntityType ?? ''}>
                                <Select.Trigger
                                    error={!!state.errors.sherpaEntityType?.message}
                                    className="w-full bg-white h-10">
                                    <Select.Value placeholder="Select" />
                                </Select.Trigger>
                                <Select.Content>
                                    {entityTypes.map(item => <Select.Item
                                        key={item.value}
                                        value={item.value}>
                                        <div className="flex items-center gap-2">
                                            <Icon name={item.icon} width={18} height={18} /> {item.label}
                                        </div>
                                    </Select.Item>)}
                                </Select.Content>
                            </Select>
                        </Stack>
                        <Stack space='sm'>
                            <Label variant='secondary' htmlFor="templateId" size='sm'>
                                Needs List Template
                            </Label>
                            <Select
                                onValueChange={value => state.onFieldChange('templateId', value)}
                                value={state.watchTemplateId}
                            >
                                <Select.Trigger
                                    error={!!state.errors.templateId?.message}
                                    className="w-full bg-white h-10">
                                    <Select.Value placeholder="Select" />
                                </Select.Trigger>
                                <Select.Content>
                                    {state.templates.map(item => <Select.Item
                                        key={item.id}
                                        value={item.id}>
                                        {item.name}
                                    </Select.Item>)}
                                </Select.Content>
                            </Select>
                        </Stack>
                        <Stack space='sm'>
                            <Label variant='secondary' htmlFor="loanRole" size='sm'>
                                Loan Role
                            </Label>
                            <Select

                                onValueChange={value => state.onFieldChange('loanRole', value)}
                                value={state.watchLoanRole ?? ''}
                            >
                                <Select.Trigger
                                    error={!!state.errors.loanRole?.message}
                                    className="w-full bg-white h-10">
                                    <Select.Value placeholder="Select" />
                                </Select.Trigger>
                                <Select.Content>
                                    <Select.Item value={'AFFILIATE'}>
                                        Affiliate
                                    </Select.Item>
                                    <SbaLoanGuard
                                        mode="HIDE"
                                        loanViewType={state.loan.template?.loanViewType}>
                                        <Select.Item value={'APPLICANT'}>
                                            Borrower
                                        </Select.Item>
                                    </SbaLoanGuard>
                                    <SbaLoanGuard
                                        loanViewType={state.loan.template?.loanViewType}>
                                        <Select.Item value={'EPC'}>
                                            Eligible Passive Company
                                        </Select.Item>
                                    </SbaLoanGuard>
                                    <SbaLoanGuard
                                        mode="HIDE"
                                        loanViewType={state.loan.template?.loanViewType}>
                                        <Select.Item value={'GUARANTOR'}>
                                            Guarantor
                                        </Select.Item>
                                    </SbaLoanGuard>
                                    <Select.Item value={'RELATED_NON_AFFILIATE'}>
                                        Related Non-Affiliate
                                    </Select.Item>
                                </Select.Content>
                            </Select>
                        </Stack>
                        <Stack space='sm'>
                            <Label variant='secondary' htmlFor="entityId" size='sm'>
                                Name
                            </Label>
                            <Input
                                autoFocus
                                {...state.register('name')}
                                error={!!state.errors.name?.message}
                                variant='sm'
                                placeholder='Name'
                                wrapClassName="h-10"
                                className='w-full focus-within:border-blue-100'
                            />
                        </Stack>
                        {state.watchSherpaEntityType === 'INDIVIDUAL' && <Stack space='sm'>
                            <Label variant='secondary' htmlFor="emailAddress" size='sm'>
                                Email Address
                            </Label>
                            <Input
                                autoFocus
                                {...state.register('emailAddress')}
                                error={!!state.errors.emailAddress?.message}
                                variant='sm'
                                placeholder='Email Address'
                                wrapClassName="h-10"
                                className='w-full focus-within:border-blue-100'
                            />
                            <Text variant='destructive' size='sm'>
                                {state.errors.emailAddress?.message}
                            </Text>
                        </Stack>}
                    </div>}
                </Stack>
                <Dialog.Footer className="gap-3 flex-row justify-end">
                    <Dialog.Close asChild>
                        <Button
                            onClick={props.onCancel}
                            variant="outline">
                            Cancel
                        </Button>
                    </Dialog.Close>
                    <Tooltip delayDuration={1000}>
                        <Tooltip.Trigger asChild>
                            <Button
                                type='submit'
                                disabled={!state.isValid}
                                loading={state.isSubmitting}
                                className="w-48 max-w-full truncate inline text-center"
                            >
                                Save And Continue
                            </Button>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            Save And Continue
                        </Tooltip.Content>
                    </Tooltip>
                </Dialog.Footer>
            </form>
        </Dialog.Content>
    </Dialog>)
}