import Icon from 'components/dist/atoms/Icon';
import Text from 'components/dist/atoms/Text';
import { TextProps } from 'components/dist/atoms/Text/Text';
import { FormElementV2ResponseDto } from "src/backend"
import { FormElementLabelModifierType } from 'src/types/formelement';

interface FormElementStatusIconProps {
    status: FormElementV2ResponseDto['status'] | 'OPEN_FILE_REQUEST'
    width?: number
    height?: number
    iconClassName?: string
}
export const FormElementStatusIcon = (props: FormElementStatusIconProps) => {
    if (!FormElementStatusSetting[props.status]) return null

    return <Icon
        data-status={props.status}
        name={FormElementStatusSetting[props.status].icon}
        width={props.width ?? FormElementStatusSetting[props.status].width}
        className={`${props.iconClassName ?? FormElementStatusSetting[props.status].iconClassName} shrink-0`}
        height={props.height ?? FormElementStatusSetting[props.status].height}
        strokeWidth={1.5}
    />
}

// create interface that extends Text component
interface FormElementStatusIconPropsWithText extends FormElementStatusIconProps, TextProps {
}

export const FormElementStatusLabel = (props: FormElementStatusIconPropsWithText) => {
    const { status, width, height, iconClassName, ...rest } = props
    if (!FormElementStatusSetting[props.status]) return null
    return <Text size="sm" {...rest} truncate>
        {FormElementStatusSetting[props.status].label}
    </Text>
}

export const FormElementModifierLabel = (props: TextProps & {
    label: FormElementLabelModifierType
}) => {
    const { label, ...rest } = props
    return <Text size="sm" {...rest} truncate >
        {FormElementModifierLabelList[label].label}
    </Text>
}

export const FormElementStatusAction = (props: FormElementStatusIconProps) => {
    if (!FormElementStatusSetting[props.status]) return null
    return <Text size="sm" weight='medium'>
        {FormElementStatusSetting[props.status].action}
    </Text>
}

export const FormElementModifierLabelList = ({
    AUDITABLE: {
        label: 'Audit',
    },
    SUBMISSION: {
        label: 'Submission',
    },
    NEEDS_LEGAL_REVIEW: {
        label: 'Legal Review',
    },
})

export const FormElementStatusSetting = ({
    OPEN: {
        label: "Open Form",
        action: "",
        icon: "InfoEmpty" as const,
        iconClassName: "text-gray-neutral-100",
        width: 16,
        height: 16,
    },
    OPEN_FILE_REQUEST: {
        label: "Open File",
        action: "",
        icon: "DocumentUpload" as const,
        iconClassName: "text-gray-neutral-100",
        width: 20,
        height: 23,
    },
    IN_PROGRESS: {
        label: "In Progress",
        icon: "MinusCircleSolid" as const,
        action: "In progress",
        iconClassName: "text-blue-70",
        width: 16,
        height: 16,
    },
    SUBMITTED: {
        label: "Submitted",
        icon: "InfoCircleSolid" as const,
        action: "Submit",
        iconClassName: "text-yellow-60",
        width: 16,
        height: 16,
    },
    REVIEWING: {
        label: "Reviewing",
        icon: "InfoCircleSolid" as const,
        action: "Review",
        iconClassName: "text-blue-80",
        width: 16,
        height: 16,
    },
    REJECTED: {
        label: "Rejected",
        action: "Reject",
        icon: "MinusCircleSolid" as const,
        iconClassName: "text-destructive",
        width: 16,
        height: 16,
    },
    ACCEPTED: {
        label: "Accepted",
        icon: "CheckCircleSolid" as const,
        action: "Accept",
        iconClassName: "text-green-flowkit",
        width: 16,
        height: 16,
    },
    NEEDS_ATTENTION: {
        label: "Needs Attention",
        icon: "InfoCircleSolid" as const,
        action: "Needs Attention",
        iconClassName: "text-yellow-75",
        width: 16,
        height: 16,
    },
    NEEDS_LEGAL_REVIEW: {
        label: "Needs Legal Review",
        icon: "ArrowUpRightCircleSolid" as const,
        action: 'Needs Legal Review',
        iconClassName: "text-gray-neutral-66",
        width: 16,
        height: 16,
    },
})