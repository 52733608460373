import { j as r } from "./jsx-runtime-D0AQ1Mn7.js";
import "./Accordion-6RJGoeje.js";
import "./Avatar-DNKFm3wh.js";
import "./Badge-QBVLNL8O.js";
import "./Button-BOVHAiPs.js";
import "./DropdownMenu-BmUInubt.js";
import "./Icon-CtH7buGH.js";
import "./Input-CyIITBNc.js";
import "./Label-BtfPjSeJ.js";
import { T as i } from "./Text-B4fIcnEL.js";
import "./Tooltip-ChPGW92B.js";
import { c as e } from "./utils-CJ9afRe1.js";
import "./Stack-BZqOy47V.js";
import "./Switch-D2_ymNuk.js";
import "./Toast-D1jXuM_f.js";
import "react";
import "./Toggle-BDJtD566.js";
const v = (t) => /* @__PURE__ */ r.jsxs(
  "div",
  {
    className: e("w-full max-w-fit rounded-md bg-white p-6 gap-4 flex flex-col items-center justify-center", t.className),
    children: [
      /* @__PURE__ */ r.jsx(i, { children: t.title }),
      /* @__PURE__ */ r.jsx("div", { className: "w-6 h-6 aspect-square rounded-full z-0 border-2 border-blue-100 border-t-transparent animate-spin" }),
      /* @__PURE__ */ r.jsx(i, { variant: "secondary", size: "xs", children: t.hint })
    ]
  }
);
export {
  v as L
};
