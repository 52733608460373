import { useState } from "react";
import { useAuth } from "src/hooks/use-auth";
import { useGetLoanByIdQuery } from "src/services/loanApi";
import { createRecipientList } from "src/utils/loan/create-recipient-list";

import { AssignFolderDroppedItemsProps } from "./assign-folder-dropped-items.types";

export const useAssignFolderDroppedItems = (props: AssignFolderDroppedItemsProps) => {
    const { user } = useAuth();
    const [selectedIds, setSelectedIds] = useState<string[]>(
        props.targetElement.sharedInfo.map(share => share.sharedWithUser.id)
    );

    const { data: loan } = useGetLoanByIdQuery(props.targetElement.loanId);

    const teams = createRecipientList(
        loan?.loanRoles,
        [],
        user,
        []
    )

    const onUserToggle = (userId: string) => {
        setSelectedIds((prev) => {
            if (prev.includes(userId)) {
                return prev.filter((id) => id !== userId);
            } else {
                return [...prev, userId];
            }
        });
    }

    const onConfirmClick = () => {
        props.onConfirm(selectedIds);
    }

    return {
        teams,
        selectedIds,
        onUserToggle,
        onConfirmClick,
    } as const;
}