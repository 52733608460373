import { t as s, c as i, s as n } from "./format-DTUgKQ74.js";
function o(a, t) {
  const e = s(a);
  return isNaN(t) ? i(a, NaN) : (t && e.setDate(e.getDate() + t), e);
}
function f(a, t) {
  const e = n(a), r = n(t);
  return +e == +r;
}
function u(a, t) {
  const e = s(a), r = s(t);
  return e.getFullYear() === r.getFullYear();
}
function D(a, t) {
  return o(a, -t);
}
export {
  o as a,
  f as b,
  u as i,
  D as s
};
