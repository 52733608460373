import type { LinkProps as NextLinkProps } from 'next/link';
import type { EntityResponseDto } from "src/backend";
import { Route } from "src/constants/routing";

export const getEntityEditUrl = (sherpaEntity: EntityResponseDto, loanId: string, query: Record<string, string | number>): NextLinkProps['href'] => {
    if (!sherpaEntity) {
        return null;
    }
    switch (sherpaEntity.type) {
        case 'ASSET':
        case 'RE_ASSET':
            return {
                pathname: Route.LOAN_ASSET_EDIT,
                query: {
                    loanId,
                    assetId: sherpaEntity.id,
                    ...query,
                }
            }
        case 'COMPANY':
            return {
                pathname: Route.LOAN_COMPANY_EDIT,
                query: {
                    loanId,
                    companyId: sherpaEntity.id,
                    ...query,
                }
            }
        case 'INDIVIDUAL':
            return {
                pathname: Route.EDIT_LOAN_PRINCIPAL,
                query: {
                    loanId,
                    personId: query.personId,
                    ...query,
                }
            }
        case 'TRUST':
            return {
                pathname: Route.LOAN_TRUST_EDIT,
                query: {
                    loanId,
                    trustId: sherpaEntity.id,
                    ...query,
                }
            }
        default:
            return null;
    }
}