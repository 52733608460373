import { j as t } from "./jsx-runtime-D0AQ1Mn7.js";
import { forwardRef as p } from "react";
import { I as a } from "./index-BijjiL52.js";
import "./Accordion-6RJGoeje.js";
import "./Avatar-DNKFm3wh.js";
import "./Badge-QBVLNL8O.js";
import "./Button-BOVHAiPs.js";
import "./DropdownMenu-BmUInubt.js";
import "./Icon-CtH7buGH.js";
import { I as i } from "./Input-CyIITBNc.js";
import "./Label-BtfPjSeJ.js";
import "./Text-B4fIcnEL.js";
import "./Tooltip-ChPGW92B.js";
import "./Stack-BZqOy47V.js";
import "./Switch-D2_ymNuk.js";
import "./Toast-D1jXuM_f.js";
import "./Toggle-BDJtD566.js";
const e = p(
  (m, o) => /* @__PURE__ */ t.jsx(a, { ...m, inputRef: o, mask: "999-999-9999", maskChar: "_", children: (r) => /* @__PURE__ */ t.jsx(
    i,
    {
      wrapClassName: r.wrapClassName,
      ...r,
      disabled: m.disabled,
      type: "tel",
      placeholder: "000-000-0000"
    }
  ) })
);
e.displayName = "PhoneNumberInput";
export {
  e as default
};
