import { CheckedState } from "components/dist/atoms/Checkbox";
import { format } from "date-fns";
import { FormikHelpers, useFormik } from "formik";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { AppUserDTO2, ElementModifierType, PriorityType } from "src/backend";
import { TeamMemberSelectListAssignedListItem } from "src/components/v2/team-member-select-list/team-member-select-list.types";
import { QUERY_PARAM_FORM_ELEMENT_ID } from "src/constants/query-params";
import { useGetElements } from "src/hooks/use-get-elements";
import { useUser } from "src/hooks/use-user";
import { packageApi, useCreateElementsMutation, useCreateSharedInfoElementMutation, useDeleteSharedInfoElementMutation, useUpdateElementsMutation } from "src/services/packageApi";
import { getLoanFormElements } from "src/slices/form-element";
import { useDispatch } from "src/store";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import { filterPriority, sortPriorities } from "src/utils/form-elements";

import { FormElementFormProps } from "./edit-form-element-details.component";
import { formElementFormValidation } from "./form-element-form.validation";

interface FormElementFormValues {
    id: string,
    title: string;
    description: string;
    includeWithMessage: boolean;
    // modifiers
    canFill: CheckedState;
    canExpire: CheckedState;
    needsLegalReview: CheckedState;
    submission: CheckedState;
    notary: CheckedState;
    wetSign: CheckedState;
    audit: CheckedState;
    // end modifiers
    entityId: string;
    parentId: string;
    priority: PriorityType;
    dueDate: string;
    isEditingEntityEnabled: boolean;
    isConfirmationDialogOpen: boolean;
    assignedList: TeamMemberSelectListAssignedListItem[];
}

const getFormattedDueDate = (dueDate: string): string => {
    return dueDate ? format(new Date(dueDate), 'dd/MM/yyyy') : null;
}

const getDueDateStatus = (dueDate: string): 'success' | 'warning' | 'danger' => {
    // if due date is due, return 'danger'
    // else if due date is within 7 days, return 'warning'
    // else return 'success'
    if (!dueDate) {
        return 'success';
    } else if (new Date(dueDate) < new Date()) {
        return 'danger';
    } else if (new Date(dueDate) < new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)) {
        return 'warning';
    } else {
        return 'success';
    }
};

const PriorityTypeList = {
    "Unset": "Unset",
    "UNSET": "UNSET",
    "HIDDEN": "HIDDEN",
    "PRIVATE": "PRIVATE",
    "IGNORE": "IGNORE",
    "BLOCKER": "BLOCKER",
    "LOW": "LOW",
    "MEDIUM": "MEDIUM",
    "HIGH": "HIGH",
    "CRITICAL": "CRITICAL",
    "URGENT": "URGENT"
}

const priorities = Object.keys(PriorityTypeList)
    .filter(filterPriority)
    .sort(sortPriorities);

export const useFormElementFormState = (props: FormElementFormProps) => {
    const elementState = useGetElements({ loanId: props.loanId });
    const router = useRouter();
    const dispatch = useDispatch();
    const [createElements] = useCreateElementsMutation();
    const [updateElements] = useUpdateElementsMutation();
    const [createSharedInfoElement] = useCreateSharedInfoElementMutation();
    const [removeSharedInfoElement] = useDeleteSharedInfoElementMutation();
    const userState = useUser();
    const nonNewEditIds = props.elementIds.filter(id => id !== 'new');
    const elementsToEdit = elementState.list?.filter(element => nonNewEditIds.includes(element.id));
    const elementToEdit = elementsToEdit.length === 1 ? elementsToEdit?.[0] : null;
    const isEdit = nonNewEditIds.length > 0;
    let initialValues: Partial<FormElementFormValues> = {};

    const entityIds = elementsToEdit
        .filter(element => element.sherpaEntityId)
        .map(element => element.sherpaEntityId)
    // check if we are editing multiple elements with different sherpaEntityId
    const isEditingMultipleEntities = entityIds
        .filter((value, index, self) => self.indexOf(value) === index)
        .length > 1;

    if (elementsToEdit.length > 1) {
        initialValues = {
            id: 'PLACEHOLDER',
            title: 'PLACEHOLDER',
            canExpire: false,
            description: '',
            includeWithMessage: false,
            entityId: isEditingMultipleEntities ? '' : entityIds[0],
            parentId: 'PLACEHOLDER',
            priority: null,
            dueDate: '',
            isEditingEntityEnabled: !isEditingMultipleEntities,
            isConfirmationDialogOpen: false,
            assignedList: getSelectedList(elementsToEdit),
            canFill: getModifierCheckedState('FILLABLE_FORM', elementsToEdit),
            needsLegalReview: getModifierCheckedState('NEEDS_LEGAL_REVIEW', elementsToEdit),
            audit: getModifierCheckedState('AUDITABLE', elementsToEdit),
            wetSign: getModifierCheckedState('NEEDS_WET_SIG', elementsToEdit),
            submission: getModifierCheckedState('SUBMISSION', elementsToEdit),
            notary: getModifierCheckedState('NEEDS_NOTARY', elementsToEdit),
        }
    } else if (elementsToEdit.length === 1) {
        initialValues = {
            id: elementToEdit.id,
            title: elementToEdit.originalTitle,
            canExpire: !!elementToEdit.canHaveExpiration,
            includeWithMessage: elementToEdit.includeDescription,
            description: elementToEdit.description ?? "",
            // modifiers
            canFill: elementToEdit.modifiers.includes('FILLABLE_FORM'),
            needsLegalReview: elementToEdit.modifiers.includes('NEEDS_LEGAL_REVIEW'),
            audit: elementToEdit.modifiers.includes('AUDITABLE'),
            wetSign: elementToEdit.modifiers.includes("NEEDS_WET_SIG"),
            submission: elementToEdit.modifiers.includes('SUBMISSION'),
            notary: elementToEdit.modifiers.includes("NEEDS_NOTARY"),
            // end modifiers
            entityId: getInitialSherpaEntityId(elementToEdit),
            parentId: elementToEdit.parentId,
            priority: elementToEdit.priorityType,
            dueDate: elementToEdit.expireDate,
            isEditingEntityEnabled: true,
            isConfirmationDialogOpen: false,
            assignedList: elementToEdit.sharedInfo.map(sharedInfo => ({
                user: sharedInfo.sharedWithUser,
                role: sharedInfo.sharedWithUser.loggedCompanyRole,
                type: true,
            }))
        }
    }
    const onPreSubmitCheck = (values: FormElementFormValues, formikHelpers: FormikHelpers<FormElementFormValues>) => {
        // check if selected entity is different than any of the elements entity
        if (values.isEditingEntityEnabled && elementsToEdit.length > 1 && elementsToEdit.some(element => element.sherpaEntityId !== values.entityId)) {
            formikHelpers.setFieldValue('isConfirmationDialogOpen', true);
            formik.setSubmitting(false);
        } else {
            onSubmit(values, formikHelpers, elementsToEdit.map(element => element.id));
        }
    };

    const getModifiers = (values: FormElementFormValues, previousModifiers: FormElementV2ResponseDtoExtended['modifiers']): ElementModifierType[] => {
        const modifiers: ElementModifierType[] = [];
        const modifierMap: { [key: string]: ElementModifierType } = {
            canFill: 'FILLABLE_FORM',
            needsLegalReview: 'NEEDS_LEGAL_REVIEW',
            audit: 'AUDITABLE',
            submission: 'SUBMISSION',
            wetSign: 'NEEDS_WET_SIG',
            notary: 'NEEDS_NOTARY'
        };
        Object.keys(modifierMap).forEach(key => {
            const checkState: CheckedState = values[key];
            const isPreviouslyAdded = checkState === 'indeterminate' && previousModifiers.includes(modifierMap[key]);
            if (checkState === true || isPreviouslyAdded) {
                modifiers.push(modifierMap[key]);
            }
        });

        const entityModifiers = ['CONSTRUCTION', 'CLOSING', 'PROJECT'];
        if (entityModifiers.includes(values.entityId)) {
            modifiers.push(values.entityId as ElementModifierType);
        }
        return modifiers;
    }

    const shareElement = async (packageInfo: FormElementV2ResponseDtoExtended, assignedList: TeamMemberSelectListAssignedListItem[]) => {
        if (userState.isBorrower) {
            // get first element from packageInfo
            await createSharedInfoElement({
                shares: [{
                    id: null,
                    infoId: packageInfo.id,
                    loanId: props.loanId,
                    permissions: ['EDIT', 'VIEW'],
                    sharedByUserId: userState.user.id,
                    sharedWithUserId: userState.user.id,
                }]
            });
        } else {
            // if user is not a borrower we need to share the file with selected users
            // that have type set to true
            const createShareAssignedList = assignedList.filter(assigned => assigned.type === true);
            const indeterminateAssignedList = assignedList.filter(assigned => assigned.type === 'indeterminate');
            // remove any shares with users that are not on create or indeterminateAssignedList
            const sharesOfUsersToRemove = packageInfo.sharedInfo.filter(sharedInfo => {
                const userId = sharedInfo.sharedWithUser.id;
                return !createShareAssignedList.some(assigned => assigned.user.id === userId) &&
                    !indeterminateAssignedList.some(assigned => assigned.user.id === userId);
            });
            if (sharesOfUsersToRemove.length > 0) {
                await removeSharedInfoElement({
                    shares: sharesOfUsersToRemove.map(sharedInfo => ({
                        id: sharedInfo.id,
                        infoId: packageInfo.id,
                        loanId: props.loanId,
                        permissions: ['EDIT', 'VIEW'],
                        sharedByUserId: sharedInfo.sharedByUser.id,
                        sharedWithUserId: sharedInfo.sharedWithUser.id,
                    }))
                });
            }
            if (createShareAssignedList.length > 0) {
                await createSharedInfoElement({
                    shares: createShareAssignedList.map(assigned => ({
                        id: null,
                        infoId: packageInfo.id,
                        loanId: props.loanId,
                        permissions: ['EDIT', 'VIEW'],
                        sharedByUserId: userState.user.id,
                        sharedWithUserId: assigned.user.id,
                    }))
                });
            }

        }
    }
    const onSubmit = async (values: FormElementFormValues, formikHelpers: FormikHelpers<FormElementFormValues>, elementsIds: string[]) => {
        formikHelpers.setSubmitting(true);
        const selectedElements = elementsToEdit.filter(element => elementsIds.includes(element.id));
        try {
            const sherpaEntityId = (!!values.entityId && !isSherpaEntityIdAModifier(values.entityId) && values.isEditingEntityEnabled) ? values.entityId : null;
            // if we are updating, we need to update the element
            if (isEdit) {
                await updateElements({
                    elements: selectedElements.map(element => {
                        const modifiers = [...getModifiers(values, element.modifiers)]
                        element?.modifiers.forEach(modifier => {
                            if (!['FILLABLE_FORM', 'NEEDS_LEGAL_REVIEW', 'AUDITABLE', 'SUBMISSION', 'NEEDS_WET_SIG', 'NEEDS_NOTARY'].includes(modifier)) {
                                modifiers.push(modifier);
                            }
                        });
                        return ({
                            id: element.id,
                            loanId: props.loanId,
                            title: selectedElements.length === 1 ? values.title : element.originalTitle,
                            storageType: 'FILE',
                            description: values.description,
                            canHaveExpiration: !!values.canExpire,
                            expireDate: values.dueDate,
                            needsLegalReview: !!values.needsLegalReview,
                            includeDescription: values.includeWithMessage,
                            sherpaEntityId,
                            parentId: element.parentId,
                            priorityType: values.priority,
                            modifiers,
                        })
                    }),
                    multiSelect: selectedElements.length > 1
                })
                const pendingRequest = packageApi.util.getRunningOperationPromise('getLoanElements', {
                    id: props.loanId,
                    view: 'CONVENTIONAL',
                    status: 'ACTIVE'
                })
                await pendingRequest
                await Promise.all(selectedElements.map(element => shareElement(element, values.assignedList)));
                // if only one element is being edited, we can show the toast with the element title
                // otherwise we show toast with n items updated
                if (selectedElements.length === 1) {
                    toast.success(`${values.title} updated successfully`);
                } else {
                    toast.success(`${selectedElements.length} items updated successfully`);
                }
            } else {
                const modifiers = [...getModifiers(values, [])]

                const createdElements = await createElements({
                    elements: [{
                        loanId: props.loanId,
                        title: values.title,
                        storageType: 'FILE',
                        description: values.description,
                        canHaveExpiration: !!values.canExpire,
                        expireDate: values.dueDate,
                        needsLegalReview: !!values.needsLegalReview,
                        includeDescription: values.includeWithMessage,
                        sherpaEntityId,
                        parentId: values.parentId,
                        priorityType: values.priority,
                        modifiers,
                    }],
                    multiSelect: false
                }).unwrap();
                const pendingRequest = packageApi.util.getRunningOperationPromise('getLoanElements', {
                    id: props.loanId,
                    view: 'CONVENTIONAL',
                    status: 'ACTIVE'
                })
                await pendingRequest
                await Promise.all(createdElements.list.map(element => shareElement(element, values.assignedList)));
                // navigate to created element
                router.push({
                    pathname: router.pathname,
                    query: {
                        ...router.query,
                        [QUERY_PARAM_FORM_ELEMENT_ID]: createdElements.list[0].id
                    }
                })
                // New File Request created on {{View/Folder name}} successfully
                toast.success(`New File Request created on ${values.title} successfully`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            await dispatch(getLoanFormElements(props.loanId, true));
            formikHelpers.setSubmitting(false);
            props.onSuccess?.();
        }
    }

    const formik = useFormik<FormElementFormValues>({
        validationSchema: formElementFormValidation,
        enableReinitialize: true,
        initialValues: {
            id: '',
            title: '',
            description: '',
            canFill: false,
            canExpire: false,
            needsLegalReview: false,
            submission: false,
            wetSign: false,
            notary: false,
            audit: false,
            includeWithMessage: false,
            entityId: '',
            parentId: props.parentId,
            priority: null,
            dueDate: '',
            isEditingEntityEnabled: true,
            isConfirmationDialogOpen: false,
            assignedList: [],
            ...initialValues
        },
        onSubmit: onPreSubmitCheck,
    });

    const handleCheckChange = (name: string, checked: boolean) => {
        formik.setFieldValue(name, checked);
    };

    const onDueDateSelected = (date: Date) => {
        formik.setFieldValue('dueDate', date);
    };

    const onEntitySelected = (entityId: string) => {
        formik.setFieldValue('entityId', entityId);
    };

    const onPrioritySelected = (priority: PriorityType) => {
        formik.setFieldValue('priority', priority);
    }

    const entities = [...props.loanEntities.map(entity => ({
        id: entity.sherpaEntity.id,
        name: entity.sherpaEntity.name,
    }))];
    if ((!formik.initialValues.entityId || isSherpaEntityIdAModifier(formik.initialValues.entityId))) {
        entities.push(...[
            {
                id: 'PROJECT',
                name: 'Project Docs'
            }, {
                id: 'CLOSING',
                name: 'Closing Docs'
            }, {
                id: 'CONSTRUCTION',
                name: 'Construction Docs'
            }]);
    }

    let title = 'Create New File Request';
    if (nonNewEditIds.length > 1) {
        title = `Edit ${nonNewEditIds.length} Items Details`;
    } else if (nonNewEditIds.length === 1) {
        title = `Edit ${formik.initialValues.title} Details`;
    }

    const onConfirmationDialogCancel = () => {
        formik.setFieldValue('isConfirmationDialogOpen', false);
    }

    const onConfirmationDialogConfirm = (elementsIds: string[]) => {
        const elementIdsAlreadyAssigned = elementsToEdit
            .filter(element => element.sherpaEntityId === formik.values.entityId)
            .map(element => element.id);
        onSubmit(formik.values, formik, [...elementsIds, ...elementIdsAlreadyAssigned]);
    }

    const onAssignedUserListChange = (users: AppUserDTO2[]) => {
        formik.setFieldValue('assignedList', users.map(user => {
            const assigned = formik.values.assignedList.find(assigned => assigned.user.id === user.id);
            return ({
                user,
                role: user.loggedCompanyRole,
                type: assigned?.type ?? true,
            })
        }));
    };

    const entityToEditName = props.loanEntities.find(entity => entity.sherpaEntity.id === formik.values.entityId)?.sherpaEntity?.name ?? "Project Docs";

    return {
        onDueDateSelected,
        handleCheckChange,
        onEntitySelected,
        onPrioritySelected,
        onConfirmationDialogCancel,
        onConfirmationDialogConfirm,
        onAssignedUserListChange,
        entityToEditName,
        isEditingMultipleEntities,
        title,
        isEdit,
        elementsToAssignNewEntity: elementsToEdit.filter(element => element.sherpaEntityId !== formik.values.entityId),
        isMultiEdit: nonNewEditIds.length > 1,
        isLoading: elementState.isLoading || elementState.isLoading,
        isSubmitting: formik.isSubmitting,
        priorities,
        formik,
        entities,
        descriptionLength: formik.values.description.length,
        dueDateStatus: getDueDateStatus(formik.values.dueDate),
        formattedDueDate: getFormattedDueDate(formik.values.dueDate),
    } as const;
};

// a function that takes a modifier name and list of FormElementV2ResponseDtoExtended and returns the checked state of the checkbox 
const getModifierCheckedState = (modifier: ElementModifierType, elements: FormElementV2ResponseDtoExtended[]): CheckedState => {
    // if all elements have the modifier, return true
    if (elements.every(element => element.modifiers.includes(modifier))) {
        return true;
    }
    // if no elements have the modifier, return false
    if (elements.every(element => !element.modifiers.includes(modifier))) {
        return false;
    }
    // if some elements have the modifier, return 'mixed'
    return 'indeterminate';
}

// a function that takes a list of FormElementV2ResponseDtoExtended and returned selectedList
// users should be unique in the list
// also if a user is in multiple FormElementV2ResponseDtoExtended sharedInfo, the type should true
// if user in some of the sharedInfos then type should be indeterminate
// if user is not in any of the sharedInfos then type should be false
const getSelectedList = (elements: FormElementV2ResponseDtoExtended[]): TeamMemberSelectListAssignedListItem[] => {
    const userMap = new Map<string, TeamMemberSelectListAssignedListItem>();

    elements.forEach(element => {
        element.sharedInfo.forEach(sharedInfo => {
            const userId = sharedInfo.sharedWithUser.id;
            if (!userMap.has(userId)) {
                userMap.set(userId, {
                    user: sharedInfo.sharedWithUser,
                    role: sharedInfo.sharedWithUser.loggedCompanyRole,
                    type: elements.every(sharedElement => sharedElement.sharedInfo.some(subSharedInfo => subSharedInfo.sharedWithUser.id === userId)) ? true : 'indeterminate',
                });
            } else {
                const userEntry = userMap.get(userId);
                if (userEntry.type === true && !elements.every(sharedElement => sharedElement.sharedInfo.some(subSharedInfo => subSharedInfo.sharedWithUser.id === userId))) {
                    userEntry.type = 'indeterminate';
                }
            }
        });
    });

    return Array.from(userMap.values());
}

const isSherpaEntityIdAModifier = (sherpaEntityId: string): boolean => {
    return ['CONSTRUCTION', 'CLOSING', 'PROJECT'].includes(sherpaEntityId);
}


const getInitialSherpaEntityId = (element: FormElementV2ResponseDtoExtended): string => {
    // if element has a sherpaEntityId, return it
    if (element.sherpaEntityId) {
        return element.sherpaEntityId;
    }
    // otherwise if element has a modifier of type CONSTRUCTION, CLOSING or PROJECT, return that
    if (element.modifiers.some(modifier => isSherpaEntityIdAModifier(modifier))) {
        return element.modifiers.find(modifier => isSherpaEntityIdAModifier(modifier)) || "";
    }
    // return PROJECT which is default folder
    return "PROJECT";
}