import { getFilePath } from "./get-file-path";

// returns the folders out of a file path
export const getFilePathFolders = (file: File) => {
    const [, ...folders] = getFilePath(file)
        .replace('./', '')
        .split('/')
        .filter(folder => folder !== '')
        .reverse();
    return folders.reverse();
};