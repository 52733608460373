import { LoanEntityLabel, SherpaEntityType, TemplateResponseDto } from "src/backend";

export const getTemplates = (args: {
    entityRole: LoanEntityLabel,
    type: SherpaEntityType,
    applicantEntities: TemplateResponseDto[],
    individuals: TemplateResponseDto[],
    companies: TemplateResponseDto[],
    trusts: TemplateResponseDto[],
    isAddingEntity?: boolean
}) => {
    if (args.entityRole === 'APPLICANT' && !args.isAddingEntity) {
        return args.applicantEntities.filter(template => template.sherpaEntityType === args.type);
    } else if (args.type === 'INDIVIDUAL') {
        return args.individuals;
    } else if (args.type === 'COMPANY') {
        return args.companies;
    } else if (args.type === 'TRUST') {
        return args.trusts;
    } else {
        return [];
    }
}