import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import Button from 'components/dist/atoms/Button';
import FileIcon from 'components/dist/molecules/FileIcon';
import FolderIcon from 'components/dist/molecules/FolderIcon';
import { parseISO } from "date-fns";
import type { FormElementV2RequestDto } from "src/backend";
import { FormikedTextFieldStyles } from 'src/components/common/form/formiked-fields/formiked-text-field.styles';

import { useFormElementFormHook } from "./form-element-form.hook";

interface FormElementFormProps<T> {
    onSubmit: (values: T) => void;
    onClose: () => void;
    formElement: T;
}

export const FormElementForm = <T extends Partial<FormElementV2RequestDto> & { fileName?: string, hasChildren?: boolean }>(props: FormElementFormProps<T>) => {
    const { handleSubmit, handleInputChange, errors, values, touched, setFieldTouched, setDateFieldValue } = useFormElementFormHook({
        onSubmit: props.onSubmit,
        formElement: props.formElement,
    })

    const handleNextClick = () => {
        handleSubmit();
    }

    const icon = props.formElement.storageType === 'FILE'
        ? <FileIcon fileName={props.formElement?.fileName} />
        : <FolderIcon className='text-yellow-60' type={props.formElement.hasChildren ? "DEFAULT" : "EMPTY"} />

    return <form onSubmit={handleSubmit}>
        <Grid
            sx={{ mt: 0 }}
            container
            spacing={2.25}>
            {(!props.formElement.hasExpiration || !props.formElement?.approved) && <Grid xs={12} item>
                <FormikedTextFieldStyles.TextField
                    autoFocus
                    fullWidth
                    name="title"
                    InputProps={{
                        startAdornment: <div className='pr-2'>{icon}</div>,
                        className: 'h-11 ml-2',
                    }}
                    inputProps={{
                        "aria-label": "Title"
                    }}
                    value={values.title}
                    helperText={!!errors.title ? String(errors.title) : ""}
                    error={!!errors.title}
                    onChange={handleInputChange} />
            </Grid>}
            {(!!props.formElement.hasExpiration && !!props.formElement?.approved) && <Grid item xs={12}>
                {values.hasExpiration && <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onAccept={() => setFieldTouched('expireDate')}
                        onChange={(date) => setDateFieldValue('expireDate', date)}
                        onClose={() => setFieldTouched('expireDate')}
                        renderInput={(inputProps) => (
                            <FormikedTextFieldStyles.TextField
                                {...inputProps}
                                autoFocus
                                fullWidth
                                name="expireDate"
                                variant="outlined"
                                InputLabelProps={{
                                    ...inputProps.InputLabelProps,
                                    shrink: true,
                                }}
                                InputProps={{
                                    ...inputProps.InputProps,
                                    className: 'h-11'
                                }}
                                error={Boolean(touched.expireDate && (errors.expireDate))}
                                helperText={touched.expireDate && String(errors.expireDate)}

                            />
                        )}
                        value={parseISO(values.expireDate)}
                    />
                </LocalizationProvider>}
            </Grid>}
            <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
                <Button
                    className='w-20 h-9'
                    onClick={props.onClose}
                    variant='outline'>
                    Cancel
                </Button>
                <Button
                    className='w-20 h-9'
                    onClick={handleNextClick}
                    variant='default'>
                    Save
                </Button>
            </Grid>
        </Grid>
    </form>
};