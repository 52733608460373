import Button from "components/dist/atoms/Button"
import Icon from "components/dist/atoms/Icon"
import Stack from "components/dist/atoms/Stack"
import Text from "components/dist/atoms/Text"
import FileIcon from "components/dist/molecules/FileIcon";
import FolderIcon from "components/dist/molecules/FolderIcon";
import { RenderIf } from "src/components/common/render-if";
import { FormElementFileRowStatus } from "src/components/v2/form-elements/form-element-row/form-element-row.status.component";
import { FormElementStatusIcon } from "src/components/v2/form-elements/form-element-status-icon";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import { classNames } from "src/utils/tailwind-class-names";

interface Props {
    element: FormElementV2ResponseDtoExtended;
    onClick: (element: FormElementV2ResponseDtoExtended) => void;
    onCopyHereClick: (element: FormElementV2ResponseDtoExtended) => void;
    operation: "COPY" | "MOVE";
    isActive: boolean;
    isDisabled: boolean;
    loggedInUserId: string;
}
export const CopyMoveToDialogListItem = (props: Props) => {
    return (<div className="pt-1">
        <Stack
            row
            items="stretch"
            space="sm"
            className={classNames("group overflow-hidden cursor-pointer rounded-md px-3 h-12",
                {
                    "bg-blue-50": props.isActive,
                    'hover:bg-blue-50': !props.isActive && !props.isDisabled,
                    'cursor-not-allowed opacity-50': props.isDisabled
                })}>
            <Stack
                row
                items="center"
                {...(!props.isDisabled ? {
                    onClick: () => props.onClick(props.element)
                } : {})}
                className="flex-1 overflow-hidden"
                space="sm">
                {props.element.storageType === "FOLDER" && <FolderIcon className="text-yellow-60" width={24} height={20} type={props.element.entityType} />}
                {props.element.storageType === "FILE" && <FileIcon fileName={props.element.answer?.document?.name} />}
                <Text
                    as="div"
                    size="sm"
                    truncate
                    className="flex-1 flex items-center gap-2">
                    {props.element.title}
                    {props.element.storageType === "FILE" && <FormElementFileRowStatus
                        id={props.element.id}
                        loanId={props.element.loanId}
                        isLastModifiedByUserVisible={true}
                        lastModifiedByMe={props.element.lastModifiedByUser?.id === props.loggedInUserId}
                        lastModifiedByUser={props.element.lastModifiedByUser}
                        status={props.element.status}
                        sizeFormatted={props.element.sizeFormatted}
                        showTitle={false}
                        storageType={props.element.storageType}
                        modifiers={props.element.modifiers}
                        answer={props.element.answer}
                        formElementIcon={<FileIcon fileName={props.element.answer?.document?.name} />}
                        formElementName={props.element.title}
                        description={props.element.description}
                        priorityType={props.element.priorityType}
                    >
                        <FormElementStatusIcon status={props.element.status} />
                    </FormElementFileRowStatus>}
                </Text>
            </Stack>
            <Button
                onClick={() => props.onCopyHereClick(props.element)}
                size="sm"
                className={classNames("hidden bg-opacity-10 hover:text-white hover:bg-opacity-20 self-center border-0 text-blue-100 items-center",
                    {
                        "lg:group-hover:inline-flex": !props.isDisabled,
                    })}>
                {props.operation === "COPY" ? "Copy" : "Move"} Here
            </Button>
            <RenderIf condition={props.element.storageType === "FOLDER"}>
                <div className="flex items-center">
                    <Icon name="NavArrowRight" width={20} height={20} strokeWidth={1.5} />
                </div>
            </RenderIf>
        </Stack>
    </div>)
};