import { isAfter, isBefore, isSameDay, parseISO } from "date-fns";
import { ModifiedFilterInterface } from "src/components/loans/tabs/overview/v2/loan-overview-needs-list/loan-overview-needs-list.types";

export const doesDateMatchModifiedFilter = (date: string, modified: ModifiedFilterInterface) => {
    if (!modified) return true;
    try {
        // if we only have a start date
        // get elements that have last modified date same as start date or after
        if (modified?.startDate &&
            !modified?.endDate &&
            date) {
            const startDate = modified.startDate instanceof Date ? modified.startDate : parseISO(modified.startDate);
            const lastModifiedDate = parseISO(date);
            return isSameDay(lastModifiedDate, startDate) || isAfter(lastModifiedDate, startDate);
            // use date-fns to compare dates
        }
        // else if we have both start and end date
        // get elements that have last modified date between start and end date
        else if (modified?.startDate &&
            modified?.endDate &&
            date) {
            const startDate = modified.startDate instanceof Date ? modified.startDate : parseISO(modified.startDate);
            const endDate = modified.endDate instanceof Date ? modified.endDate : parseISO(modified.endDate);
            const lastModifiedDate = parseISO(date);
            return (isSameDay(lastModifiedDate, startDate) || isAfter(lastModifiedDate, startDate)) &&
                (isSameDay(lastModifiedDate, endDate) || isBefore(lastModifiedDate, endDate));
        }
    } catch (error) {
        console.error(error);
        return true;
    }
    return true;
};