import Button from "components/dist/atoms/Button"
import Icon from "components/dist/atoms/Icon"
import Stack from "components/dist/atoms/Stack"
import Text from "components/dist/atoms/Text"
import FolderIcon from "components/dist/molecules/FolderIcon";
import { Loan } from "src/types/loan";

interface Props {
    loan: Loan;
    onClick: (loan: Loan) => void;
    onCopyHereClick: (loan: Loan) => void;
    operation: "COPY" | "MOVE";
}
export const CopyMoveToDialogLoanListItem = (props: Props) => {
    return (<div className="pt-1">
        <Stack
            row
            items="stretch"
            space="sm"
            className="group overflow-hidden cursor-pointer rounded-md px-3 h-12 hover:bg-blue-50">
            <Stack
                row
                items="center"
                onClick={() => props.onClick(props.loan)}
                className="flex-1 overflow-hidden"
                space="sm">
                <FolderIcon width={24} height={20} type="DEFAULT" />
                <Text
                    as="div"
                    size="sm"
                    truncate
                    className="flex-1 flex items-center gap-2">
                    {props.loan.projectName}
                </Text>
            </Stack>
            <Button
                onClick={() => props.onCopyHereClick(props.loan)}
                size="sm"
                className="hidden lg:group-hover:inline-flex bg-opacity-10 hover:text-white hover:bg-opacity-20 self-center border-0 text-blue-100 items-center">
                {props.operation === "COPY" ? "Copy" : "Move"} Here
            </Button>
            <div className="flex items-center">
                <Icon name="NavArrowRight" width={20} height={20} strokeWidth={1.5} />
            </div>
        </Stack>
    </div>)
};