import { j as s } from "./jsx-runtime-D0AQ1Mn7.js";
import * as l from "react";
import { c as r } from "./utils-CJ9afRe1.js";
import { I as x, T as c } from "./constants-Bg0UqjXF.js";
const f = l.forwardRef(
  ({ className: t, error: a, variant: i, children: m, wrapClassName: d, ...e }, o) => /* @__PURE__ */ s.jsxs(
    "div",
    {
      "aria-disabled": e.disabled,
      "aria-invalid": a,
      className: r(c({ error: a, className: d })),
      children: [
        /* @__PURE__ */ s.jsx(
          "textarea",
          {
            className: r(x({ variant: i, className: t })),
            ref: o,
            ...e
          }
        ),
        m
      ]
    }
  )
);
f.displayName = "Textarea";
export {
  f as default
};
