
import Box from '@mui/material/Box';
import Button from "components/dist/atoms/Button";
import Separator from "components/dist/atoms/Separator";
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from "components/dist/atoms/Tooltip";
import VerificationInput from "react-verification-input";
import { NextLinkComposed } from 'src/components/common/next-link-composed';
import { Route } from "src/constants/routing";
import { themeV2 } from "src/theme/mysherpas-theme-option";
import { getPrettyCountdownTime } from 'src/utils/get-pretty-countdown-time';

interface VerificationCodeFormProps {
    username: string;
    error: string;
    onChange: (code: string) => void;
    onResend: () => void;
    onChangeEmail?: () => void;
    onVerify?: () => void;
    value: string;
    countDown: number;
    disableAuth?: boolean;
    verifying: boolean;
}

export const VerificationCodeForm: React.FC<VerificationCodeFormProps> = (props) => {

    const usernameMasked = props.username.replace(/^(.{3})(.*)(.{3})$/, '$1***$3');
    return <div>
        <div className="gap-6 flex flex-col">
            <Text fontWeight="medium" className="text-center">
                Verify your email
            </Text>
        </div>
        <Text size="sm" className="pt-2 text-center" variant="secondary">
            Please enter the verification code sent to the email {usernameMasked}
        </Text>
        {!props.disableAuth && <Text size="sm" className="pt-2 text-center" variant="secondary">
            Not you? <NextLinkComposed to={Route.LOGIN} className="text-blue-100 underline">
                Log in
            </NextLinkComposed>
        </Text>}
        <div className='sm:px-10'>
            <Box
                sx={{
                    mt: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    '& .verification-input-container': {
                        width: 'auto'
                    },
                    '& .verification-input-character': {
                        width: 48,
                        height: 48,
                        borderRadius: themeV2.shape.borderRadius.input,
                        borderColor: themeV2.neutral.grey[80],
                        backgroundColor: 'white',
                        flexGrow: 'unset',
                        flexBasis: 'unset',
                        fontSize: 16,
                        lineHeight: '48px',
                        ...(!!props.error && {
                            borderColor: themeV2.colors.red[50],
                        }),
                        ...(props.value.length === 6 && !props.error && {
                            borderColor: themeV2.colors.green[50],
                        })
                    },
                    '& .verification-input-character-selected': {
                        outlineStyle: 'solid',
                        outlineWidth: 1,
                        outlineColor: themeV2.colors.blue[100],
                        borderColor: themeV2.colors.blue[100],
                        color: themeV2.colors.blue[100],
                    }
                }}>
                <div className="flex flex-col">
                    <VerificationInput
                        autoFocus
                        inputProps={{
                            'aria-label': "Verification Code",
                            disabled: props.verifying
                        }}
                        length={6}
                        placeholder='-'
                        onChange={props.onChange}
                        value={props.value}
                        classNames={{
                            container: "verification-input-container",
                            character: "verification-input-character",
                            characterInactive: "verification-input-character-inactive",
                            characterSelected: "verification-input-character-selected",
                        }} />
                    {!!props.error && <Text variant="destructive" size="sm" className="pt-2 w-full text-left">
                        {props.error}
                    </Text>}
                </div>
            </Box>
            {props.countDown > 0 && <Text center size="sm" className="py-9">
                Code expires in <Text as="span" variant="blue" size="sm" fontWeight="medium">
                    {getPrettyCountdownTime(props.countDown)}
                </Text>
            </Text>}
            {props.countDown === 0 && <Text center size="sm" className="py-9 text-destructive">
                Code expired. Click Resend Code to get a new code
            </Text>}
            {props.onVerify && <Button
                onClick={props.onVerify}
                data-testid='verify-code-button'
                className="w-full h-10"
                loading={props.verifying}
                type='button'>
                Verify Email
            </Button>}
            <Separator />
            <Stack row className="text-center mt-6" justify={props.onResend && props.onChangeEmail ? 'between' : "center"}>
                {props.onResend && <Tooltip>
                    <Tooltip.Trigger asChild>
                        <Button
                            type='button'
                            className="text-blue-100 py-0 h-auto"
                            onClick={props.onResend}
                            variant="ghost">
                            Resend code
                        </Button>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        Request a new code
                    </Tooltip.Content>
                </Tooltip>}
                {props.onChangeEmail && <Tooltip>
                    <Tooltip.Trigger asChild>
                        <Button
                            type='button'
                            className="text-blue-100 py-0 h-auto"
                            onClick={props.onChangeEmail}
                            variant="ghost">
                            Change email
                        </Button>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        Change the email address
                    </Tooltip.Content>
                </Tooltip>}
            </Stack>
        </div>
    </div>
}