import Button from 'components/dist/atoms/Button';
import Dialog from "components/dist/atoms/Dialog";
import Editor from 'components/dist/atoms/Editor';
import Stack from 'components/dist/atoms/Stack';

import { useSignatureEditDoalogState } from './signature-edit-dialog.state';
import { SignatureEditDialogProps } from './signature-edit-dialog.types';

export const SignatureEditDialog = (props: SignatureEditDialogProps) => {
    const state = useSignatureEditDoalogState(props);

    return <Dialog
        open
        onOpenChange={props.onDialogOpenChange}
    >
        <Dialog.Content className='sm:rounded w-full sm:max-w-[95%] sm:max-h-[95%] lg:max-h-[95%] h-full gap-0 rounded-lg overflow-hidden'>
            <Dialog.Title className="text-sm bg-black-10 capitalize border-b border-gray-neutral-80 px-4 py-2">
                {props.signature.name || 'Signature'}
            </Dialog.Title>
            <Editor.Input
                enterSubmit={false}
                name="body"
                onChange={state.onEditorChange}
                enabledTools={[
                    "fontSize", "separator", "toggleBold", "toggleItalic", "toggleUnderline", 'setColor', "setHighlight", "separator", 'setTextAlign', "toggleOrderedList", "setLink", 'setImage', 'table', 'separator', "toggleBulletList", "toggleBlockquote"
                ]}
                mentions={[]}
                value={state.value}
                className="flex-1 flex flex-col [&>_div]:flex-1 p-4"
                toolbarClassName="border-t border-gray-neutral-80 w-full"
                toolbar="AFTER" />
            <Stack row space="lg" items='center' justify="end" className='px-4 pb-4 pt-4 sm:pt-2'>
                <Dialog.Close asChild>
                    <Button
                        variant="outline"
                        className="min-w-20"
                    >
                        Cancel
                    </Button>
                </Dialog.Close>
                <Button
                    onClick={state.onSaveSignature}
                    className="min-w-20"
                >
                    Save
                </Button>

            </Stack>
        </Dialog.Content>
    </Dialog>
};