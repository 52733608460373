import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { useState as s } from "react";
import "./Accordion-6RJGoeje.js";
import "./Avatar-DNKFm3wh.js";
import "./Badge-QBVLNL8O.js";
import "./Button-BOVHAiPs.js";
import "./DropdownMenu-BmUInubt.js";
import "./Icon-CtH7buGH.js";
import { I as i } from "./Input-CyIITBNc.js";
import "./Label-BtfPjSeJ.js";
import { T as l } from "./Text-B4fIcnEL.js";
import "./Tooltip-ChPGW92B.js";
import "./Stack-BZqOy47V.js";
import "./Switch-D2_ymNuk.js";
import "./Toast-D1jXuM_f.js";
import "./Toggle-BDJtD566.js";
import { A as a } from "./AlertDialog.component-C6-J4wlj.js";
import { S as n } from "./Separator.component-C8L6rMW3.js";
const b = (m) => {
  const [t, o] = s("");
  return /* @__PURE__ */ e.jsx(
    a,
    {
      onOpenChange: m.onOpenChange,
      open: m.open,
      children: /* @__PURE__ */ e.jsxs(
        a.Content,
        {
          className: "h-auto gap-3 max-w-xs rounded-md z-full-screen",
          children: [
            /* @__PURE__ */ e.jsx(a.VisuallyHidden, { children: /* @__PURE__ */ e.jsx(a.Title, { children: "Rename" }) }),
            /* @__PURE__ */ e.jsx(l, { size: "sm", weight: "medium", className: "px-3 pt-2", children: "Rename" }),
            /* @__PURE__ */ e.jsx(n, {}),
            /* @__PURE__ */ e.jsx("div", { className: "px-3", children: /* @__PURE__ */ e.jsx(
              i,
              {
                autoFocus: !0,
                "aria-label": "Rename",
                name: "name",
                onChange: (r) => o(r.target.value),
                placeholder: "New name...",
                defaultValue: m.name
              }
            ) }),
            /* @__PURE__ */ e.jsx(n, {}),
            /* @__PURE__ */ e.jsxs(a.Footer, { children: [
              /* @__PURE__ */ e.jsx(
                a.Cancel,
                {
                  className: "w-24",
                  autoFocus: !1,
                  children: "Cancel"
                }
              ),
              /* @__PURE__ */ e.jsx(
                a.Action,
                {
                  className: "w-24",
                  onClick: () => m.onRename(t),
                  disabled: !t || m.name === t,
                  children: "Save"
                }
              )
            ] })
          ]
        }
      )
    }
  );
};
export {
  b as R
};
